<template>
  <div class="container">
    <div class="top-bar">
      <AddressModule />
    </div>
    <div class="row-flex">
      <div class="market-selection">
        <div v-if="mapState.loadingMarkets">Loading markets...</div>
        <div v-else-if="!mapState.loadingMarkets">
            <div v-for="(market, code) in mapState.markets" :key="code" class="market">
            <span class="zone-checkbox">
                <button @click="toggleMarketExpansion(code)" class="small-btn">
                {{ expandedMarkets[code] ? '↑' : '↓' }}
                </button>
                <input type="checkbox" :id="code" :value="code" v-model="selectedMarkets" @change="toggleMarketZones(code, $event.target.checked)">
                <label :for="code" class="checkbox-label">&nbsp;&nbsp;{{ code }} ({{ market.city }})</label>
            </span>
            <div v-if="expandedMarkets[code]" class="zones">
                <div v-for="zone in sortedZones(market.zones)" :key="zone.zoneNumber" class="zone">
                <div v-for="(zipCodes, zoneNumber) in zone" :key="zoneNumber">
                    <span class="zone-checkbox">
                    <input
                        type="checkbox"
                        :id="`${code}${zoneNumber}`"
                        :value="`${code}${zoneNumber}`"
                        v-model="selectedZones"
                    >
                    <label :for="`${code}${zoneNumber}`" class="checkbox-label">Zone {{ zoneNumber }}</label>
                    </span>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
      <div class="map-container">
        <l-map ref="map" :zoom="zoom" v-model:zoom="zoom" :center="mapCenter">
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
          ></l-tile-layer>
          <l-geo-json
            v-for="(geoJsonData, index) in geoJsonLayers"
            :key="index"
            :geojson="geoJsonData.data"
            :optionsStyle="styleFeature"
            :options="{ onEachFeature: onEachFeature }"
          >
          </l-geo-json>
          <l-marker :lat-lng="mapState.markerLatLng" v-if="isMarkerLatLngValid"></l-marker>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LMarker, LTileLayer, LGeoJson } from "@vue-leaflet/vue-leaflet";
import axios from 'axios';
import AddressModule from '@/components/map/AddressModule.vue';
import { mapState } from  '@/mapstore.js';
import { watch } from 'vue';

export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
    AddressModule
  },

  data() {
    return {
      zoom: 6,
      mapCenter: [32.7767, -96.7970],
      geoJsonLayers: [],
      selectedMarkets: [],
      expandedMarkets: {},
      selectedZones: [],
      processedZones: new Set(),
      colorPalette: [
        "#e6194B", "#3cb44b", "#ffe119", "#4363d8", "#f58231",
        "#911eb4", "#46f0f0", "#f032e6", "#bcf60c", "#fabebe",
        "#008080", "#e6beff", "#9a6324", "#fffac8", "#800000",
        "#aaffc3", "#808000", "#ffd8b1", "#000075", "#808080",
        "#949494", "#000000", "#fabed4", "#dcbeff", "#a3a3c2"
      ],
      colorAssignments: {},
      mapState
    };
  },

  mounted() {
    if (Object.keys(mapState.markets).length === 0) {
        mapState.fetchMarkets();
    }
    watch(() => mapState.centeredZone, this.handleCenteredZoneChange, { deep: true });
  },

  computed: {
    sortedZones() {
      return (zones) => {
        return zones.slice().sort((a, b) => {
          return a.zoneNumber - b.zoneNumber;
        });
      };
    },
    isMarkerLatLngValid() {
      return Array.isArray(mapState.markerLatLng) && 
             mapState.markerLatLng.length === 2 &&
             typeof mapState.markerLatLng[0] === 'number' &&
             typeof mapState.markerLatLng[1] === 'number';
    }
  },

  methods: {
    toggleMarketExpansion(code) {
      if (this.expandedMarkets[code]) {
        this.expandedMarkets[code] = false;
      } else {
        this.expandedMarkets[code] = true;
      }
    },
    styleFeature(feature) {
      if (feature.properties && feature.properties.zoneNumber) {
        const fillColor = this.getColorForZone(feature.properties.zoneNumber);
        return {
          weight: 1,
          color: 'black',
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 0.4
        };
      }
      return {};
    },
    onEachGeoJsonFeature(feature, layer) {
      if (feature.properties && feature.properties.zoneNumber) {
        layer.bindTooltip(`Zone ${feature.properties.zoneNumber}`);
      }
    },
    getColorForZone(zoneNumber) {
      if (!this.colorAssignments[zoneNumber]) {
        this.colorAssignments[zoneNumber] = this.colorPalette[Object.keys(this.colorAssignments).length % this.colorPalette.length];
      }
      return this.colorAssignments[zoneNumber];
    },
    toggleMarketZones(marketCode, isSelected) {
      const marketZones = mapState.markets[marketCode].zones.flatMap(zone =>
        Object.keys(zone).map(zoneNumber => `${marketCode}${zoneNumber}`)
      );
      if (isSelected) {
        this.selectedZones = [...new Set([...this.selectedZones, ...marketZones])];
      } else {
        this.selectedZones = this.selectedZones.filter(zone => !marketZones.includes(zone));
      }
    },
    async loadGeoJsonLayers(zones) {
      this.geoJsonLayers = [];
      for (const zone of zones) {
        const marketCode = zone.substring(0, 3);
        const zoneNumber = zone.substring(3);
        const geoJsonUrl = `https://ars-geojson.s3.amazonaws.com/${zone}.geojson`;
        try {
          const response = await axios.get(geoJsonUrl);
          const geoJsonData = response.data;
          geoJsonData.features.forEach(feature => {
            feature.properties.zoneNumber = zoneNumber;
            feature.properties.tooltip = `${marketCode} ${zoneNumber}`;
          });
          this.geoJsonLayers.push({ data: geoJsonData, zoneNumber, marketCode });
        } catch (error) {
          console.error(`Failed to load GeoJSON for zone ${zone}:`, error);
        }
      }
    },
    onEachFeature(feature, layer) {
      const zoneNumber = feature.properties?.zoneNumber;
      if (zoneNumber && !this.processedZones.has(zoneNumber)) {
        layer.bindTooltip(`Zone ${zoneNumber}`, {
          permanent: true,
          direction: 'center',
          className: 'zone-label'
        });
        this.processedZones.add(zoneNumber);
      }
    },
    handleCenteredZoneChange(newCenteredZone, oldCenteredZone) {
      if (newCenteredZone.length === 2) {
        this.centerMapOnZone(newCenteredZone[0], newCenteredZone[1]);
      } else if (newCenteredZone.length === 0 && oldCenteredZone.length === 2) {
        this.clearCenteredZone(oldCenteredZone);
      }
    },
    clearCenteredZone(oldCenteredZone) {
      const zoneId = `${oldCenteredZone[0]}${oldCenteredZone[1]}`;
      this.selectedZones = this.selectedZones.filter(z => z !== zoneId);
      this.geoJsonLayers = this.geoJsonLayers.filter(layer => `${layer.marketCode}${layer.zoneNumber}` !== zoneId);
    },
    async centerMapOnZone(marketCode, zoneNumber) {
        const zoneId = `${marketCode}${zoneNumber}`;
        if (!this.selectedZones.includes(zoneId)) {
            this.selectedZones.push(zoneId);
            await this.loadGeoJsonLayers([zoneId]);
        }
        this.centerMapOnGeoJson(zoneId);
    },
    async centerMapOnGeoJson(zoneId) {
      const geoJsonLayer = this.geoJsonLayers.find(layer => `${layer.marketCode}${layer.zoneNumber}` === zoneId);
      if (geoJsonLayer) {
        const bounds = this.calculateBounds(geoJsonLayer.data);
        if (bounds) {
          const centerLat = (bounds[0][0] + bounds[1][0]) / 2;
          const centerLng = (bounds[0][1] + bounds[1][1]) / 2;
          this.mapCenter = [centerLat, centerLng];
          setTimeout(() => this.zoom = 10, 400)
        }
      }
    },
    calculateBounds(geoJsonData) {
      let bounds = [[Infinity, Infinity], [-Infinity, -Infinity]];
      function extendBounds(coord) {
        bounds = [
          [Math.min(bounds[0][0], coord[1]), Math.min(bounds[0][1], coord[0])], // lat, lng
          [Math.max(bounds[1][0], coord[1]), Math.max(bounds[1][1], coord[0])]
        ];
      }
      function processCoordinates(coordinates, depth) {
        if (depth === 0) {
          extendBounds(coordinates);
        } else {
          coordinates.forEach(coord => {
            processCoordinates(coord, depth - 1);
          });
        }
      }
      geoJsonData.features.forEach(feature => {
        const coords = feature.geometry.coordinates;
        const type = feature.geometry.type;
        let depth = 0;
        if (type === 'Point') depth = 0;
        else if (type === 'LineString') depth = 1;
        else if (type === 'Polygon') depth = 2;
        else if (type === 'MultiPolygon') depth = 3;
        processCoordinates(coords, depth);
      });
      if (bounds[0][0] === Infinity) {
        return null;
      }
      return bounds;
    }
  },

  watch: {
    selectedZones: {
      handler(newZones) {
        this.loadGeoJsonLayers(newZones);
      },
      deep: true
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');

.container {
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  height: 98vh;
}

.market-selection {
  flex-basis: 270px;
  overflow-y: auto;
  background-color: #7EB2CF;
}

.top-bar {
  height: 50px;
  width: 100%;
  background-color: #7EB2CF;
  display: flex;
  align-content: center;
}

.row-flex {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: calc(98vh - 50px);
}

.market {
  margin-bottom: 10px;
}

.zones {
  margin-left: 20px;
}

.map-container {
  flex-grow: 1;
}

.small-btn {
    background-color: #2196F3;
    border-color: #2196F3;
    border: 1px;
    color: #FFFFFF;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    cursor: pointer;
    margin-bottom: 0px;
    font-size: 10px;
    padding: 5px
}

.zone-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.zone-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  background-color: #d0d0d0;
  border: 2px solid #d0d0d0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.zone-checkbox span.checkbox-label {
  pointer-events: none;
}

.zone-checkbox .checkbox-label {
  display: inline;
}

.zone-checkbox input[type="checkbox"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.zone-checkbox input[type="checkbox"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.market-selection::-webkit-scrollbar {
width: 10px; 
}
.market-selection::-webkit-scrollbar-thumb {
background: #909fac;
}
.market-selection::-webkit-scrollbar-thumb:hover  {
background: #909fac; 
}
</style>
