<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-title">ARS Job Portal</div>
      <div>
        <form @submit.prevent="submitGuestEmail">
          <input type="email" v-model="guestEmail" placeholder="Client email" required class="guest-email-input">
          <button type="submit" :disabled="emailClicked === true" class="guest-submit-button">Send</button>
        </form>
        <div v-if="guestLoginStatus">
          <span v-if="guestLoginStatus === 200">✅ Login link sent</span>
          <span v-else>❌ Error: {{ guestLoginError }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      guestEmail: '',
      guestLoginStatus: null,
      guestLoginError: '',
      emailClicked: false
    };
  },
  computed: {
    isDeveloperMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
  methods: {
    async submitGuestEmail() {
      this.emailClicked = true;
      const trimmedEmail = this.guestEmail.trim().toLowerCase();
      try {
        const response = await fetch('/client/guest/req', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: trimmedEmail })
        });
        this.guestLoginStatus = response.status;
        if (response.status !== 200) {
          this.emailClicked = false;
          const errorText = await response.text();
          this.guestLoginStatus = response.status;
          this.guestLoginError = errorText;
        }
      } catch (error) {
        this.guestLoginStatus = 500;
        this.emailClicked = false;
        this.guestLoginError = error.message;
      }
    }
  }
}
</script>

<style scoped>

.login-page {
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: #f5f5f5;
}

.login-container {
    margin: 0; 
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    width: 300px;
}

.login-title {
    font-family: 'Victor Mono', monospace;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.login-button {
    background-color: #0078d4;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.login-button:hover {
    background-color: #005a9e;
}

.guest-email-input {
  width: calc(50%);
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.guest-submit-button {
  background-color: #3b8dcc;
  color: #fff;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.guest-submit-button:disabled {
  background-color: #ddd;
}

.guest-submit-button:hover:not(:disabled) {
  background-color: #005a9e;
}
</style>
