<template>
    <div class="invoice-container">
        <button @click="submitForm"
                :class="{'button-enabled': !isSubmitted, 'button-disabled': isSubmitted}"
                class="create-button">
            Go to checkout
        </button>
        <div class="checkout-link" v-if="publicState.checkoutUrl !== ''">
            <a href="{{ publicState.checkoutUrl }}">Click here if you are not redirected</a>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { publicState } from  '@/pubstore.js';
import axios from 'axios';
export default {
    setup() {
        const isSubmitted = computed({
            get: () => publicState.isSubmitted,
            set: value => { publicState.isSubmitted = value; }
        });
        return {
            isSubmitted,
            publicState
        };
    },
    props: {
        label: String,
    },
    methods: {
        async submitForm() {
            if (!publicState.isSubmitted) {
                publicState.isSubmitted = true;
                publicState.responseMessage = `Creating rescue...`;
                if (publicState.formData.selectedServices.includes("Fuel Delivery")) {
                    if (publicState.formData.comment !== '') {
                        publicState.formData.comment += '; ';
                    }
                    publicState.formData.comment += publicState.selectedFuel;
                }
                if (publicState.selectedSide.length > 0) {
                    publicState.selectedSide.forEach(side => {
                        if (publicState.formData.comment !== '') {
                            publicState.formData.comment += '; ';
                        }
                        publicState.formData.comment += side.value;
                    });
                }
                if (publicState.hwySide !== '') {
                    if (publicState.formData.comment !== '') {
                        publicState.formData.comment += '; ';
                    }
                    publicState.formData.comment += publicState.hwySide;
                }
                let endpoint = '/public/invoice/';
                const payload = {
                    make: publicState.formData.vehicleMake,
                    model: publicState.formData.vehicleModel,
                    year: publicState.formData.vehicleYear,
                    color: publicState.formData.vehicleColor,
                    services: publicState.formData.selectedServices,
                    street: publicState.formData.street,
                    city: publicState.formData.city,
                    state: publicState.formData.state,
                    zip: publicState.formData.zip,
                    market: publicState.formData.market,
                    motoristFirst: publicState.formData.motoristFirstName,
                    motoristLast: publicState.formData.motoristLastName,
                    motoristCell: publicState.formData.motoristCell,
                    motoristEmail: publicState.formData.motoristEmail,
                    highway: publicState.formData.highwayCall,
                    googleAddress: publicState.formData.googleAddress,
                    latitude: publicState.formData.latitude,
                    longitude: publicState.formData.longitude,
                    comment: publicState.formData.comment,
                    provider: publicState.formData.provider,
                };
                if (publicState.scheduledTime !== '') {
                    payload.scheduledTime = publicState.scheduledTime
                    endpoint = '/public/invoice/sched';
                }
                try {
                    const response = await axios.post(endpoint, payload);
                    console.log("Response received:", response.data);
                    if (response.data.valid) {
                        publicState.checkoutUrl = response.data.url;
                        window.location.href = response.data.url;
                    } else {
                        publicState.responseMessage = `❌ ${response.data.error}`;
                    }
                } catch (error) {
                    console.error("Error in submitForm:", error);
                    publicState.responseMessage = `❌ ${error.message}`;
                }
            }
        }
    }
}
</script>

<style scoped>

body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.form-row {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.form-field {
    font-size: 16px;
    align-items: center;
}

button {
    color: #fff;
    text-align: center;
    background-color: #48a8dc;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6em;
    text-decoration: none;
    transition: background-color .3s,transform .2s;
    cursor: pointer;
    box-shadow: 0 20px 30px -10px rgba(24,16,99,.36);
    width: 100%;
}

button:hover {
  background-color: #2a5f7c;
  transform: translateY(-1px);
}


.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
}


@media (min-width: 1200px) {
    button {
        font-size: 1em;
    }
}

/* Media query for font size at min-width: 1600px */
@media (min-width: 1600px) {
    button {
        font-size: 1.2em;
    }
}

</style>