<template>
    <div class="form-container">
      <h1>Call script</h1>
      <hr />
      <transition name="fade">
        <div :class="{'dialogue-box': curbsidecurrent.currentCurbsideDialogue.type === 'dialogue', 'directive-box': curbsidecurrent.currentCurbsideDialogue.type === 'directive', 'end-box': curbsidecurrent.currentCurbsideDialogue.type === 'end'}">
            {{ curbsidecurrent.currentCurbsideDialogue.dialogue }}
        </div>
      </transition>

      <div v-if="curbsidecurrent.currentCurbsideDialogue.fields" class="fields-container">
        <div v-for="field in curbsidecurrent.currentCurbsideDialogue.fields" :key="field.type">
          <template v-if="field.type === 'address-field'">
            <AddressField />
          </template>
          <template v-if="field.type === 'comment-field'">
            <CommentField />
          </template>
          <template v-if="field.type === 'veh-field'">
            <VehicleField />
          </template>
          <template v-if="field.type === 'contact-field'">
            <ContactField />
          </template>
          <template v-if="field.type === 'invoice-button'">
            <InvoiceButton />
          </template>
          <template v-if="field.type === 'battery-warranty'">
            <BatteryWarranty/>
          </template>
          <template v-if="field.type === 'lug-nut-tab'">
            <LugNutTab />
          </template>
          <template v-if="field.type === 'market-field'">
            <MarketField />
          </template>
          <template v-if="field.type === 'fuel-field'">
            <FuelSelect />
          </template>
          <template v-if="field.type === 'schedule-field'">
            <ScheduleField />
          </template>
          <template v-if="field.type === 'side-field'">
            <SideSelect />
          </template>
          <template v-if="field.type === 'tow-loc-field'">
            <TowLocation />
          </template>
          <template v-if="field.type === 'tow-dest-field'">
            <TowDestination />
          </template>
          <template v-if="field.type === 'tow-call-notes'">
            <TowNotes />
          </template>
          <template v-if="field.type === 'tow-vehicle'">
            <TowVehicle />
          </template>
        </div>
      </div>

      <span v-if="scriptState.fieldsValid">
        <div v-for="option in filteredOptions" :key="option.nextNodeId"
            :class="['option-box', option.type === 'directive' ? 'directive-option' : option.type === 'dialogue' ? 'dialogue-option' : option.type === 'end' ? 'end-option' : '']"
            @click="updateDialogue(option.nextNodeId)">
          {{ option.text }}
        </div>
      </span>
      <span v-if="!scriptState.fieldsValid" class="fields-container" :style="{ 'text-align': 'center' }">
        <em>Please enter customer info before proceeding</em>
      </span>

      <div v-if="scriptState.nodeHistory.length > 1" class="directive-option" @click="goBack()">
        [Go back]
      </div>

      <div v-if="curbsidecurrent.currentCurbsideDialogue !== '1'" class="end-option" @click="startOver()">
          [Start over]
      </div>
      <span v-if="scriptState.invoiceData">
        <WoInvoice />
      </span>
      <span v-if="scriptState.towInvoice">
        <TowInvoice />
      </span>
    </div>
</template>

<script>
import curbsidescript from '@/assets/curbsidecallscript.json';
import { reactive } from 'vue';
import axios from 'axios';
import moment from 'moment-timezone';
import { scriptState, resetScriptState, current, curbsidecurrent, fetchPrice, fetchTowPrice } from '@/store.js';
import AddressField from './form/AddressField.vue';
import CommentField from './form/CommentField.vue';
import VehicleField from './form/VehicleField.vue';
import ContactField from './form/ContactField.vue';
import InvoiceButton from './form/InvoiceButton.vue';
import BatteryWarranty from './form/BatteryWarranty.vue';
import LugNutTab from './form/LugNutTab.vue';
import MarketField from './form/MarketField.vue';
import FuelSelect from './form/FuelSelect.vue';
import ScheduleField from './form/ScheduleField.vue';
import SideSelect from './form/SideSelect.vue';
import WoInvoice from './form/WoInvoice.vue';
import TowLocation from './form/TowLocation.vue';
import TowDestination from './form/TowDestination.vue';
import TowNotes from './form/TowNotes.vue';
import TowVehicle from './form/TowVehicle.vue';
import TowInvoice from './form/TowInvoice.vue';

export default {
  components: {
    AddressField,
    CommentField,
    VehicleField,
    ContactField,
    InvoiceButton,
    BatteryWarranty,
    LugNutTab,
    MarketField,
    FuelSelect,
    ScheduleField,
    SideSelect,
    TowLocation,
    TowDestination,
    TowNotes,
    TowVehicle,
    WoInvoice,
    TowInvoice
  },
    data() {
        return {
            nodes: curbsidescript.nodes,
            currentNodeId: '1'
        };
    },
    provide() {
        return {
            sharedState: this.sharedState
        };
    },
    setup() {

    const sharedState = reactive({
        stateHistory: [],
        updateStateHistory() {
            const currentState = JSON.parse(JSON.stringify(scriptState));
            this.stateHistory.push(currentState);
            history.pushState({ nodeId: curbsidecurrent.currentCurbsideDialogue.id, state: currentState }, '', '');
        }
    });

    if (scriptState.nodeHistory.length > 1) {
      const curNodeId = scriptState.nodeHistory[scriptState.nodeHistory.length - 1];
      const curNode = curbsidescript.nodes.find(node => node.id === curNodeId);
      if (curNode) {
        curbsidecurrent.currentCurbsideDialogue = curNode;
      }
    }

    function updateState(metadata) {
      metadata.forEach(item => {
        const keys = item.key.split('.');
        let ref = scriptState;
        for (let i = 0; i < keys.length - 1; i++) {
          ref = ref[keys[i]];
        }
        if (Array.isArray(ref[keys[keys.length - 1]])) {
          ref[keys[keys.length - 1]].push(item.value);
        } else {
          ref[keys[keys.length - 1]] = item.value;
        }
      });
    }

    const updateDialogue = async (nextNodeId) => {
      const nextNode = curbsidescript.nodes.find(node => node.id === nextNodeId);
      if (nextNode) {
          // Fetch price if the dialogue contains `[[price]]`
          if (nextNode.dialogue.includes('[[price]]')) {
              const price = await fetchPrice();
              nextNode.dialogue = nextNode.dialogue.replace(/\[\[price\]\]/g, price);
          }
          if (nextNode.dialogue.includes('[[tow-price]]')) {
              const price = await fetchTowPrice();
              nextNode.dialogue = nextNode.dialogue.replace(/\[\[tow-price\]\]/g, price[0]);
              nextNode.dialogue = nextNode.dialogue.replace(/\[\[tow-hold-cost\]\]/g, price[1]);
          }
          if (nextNode.dialogue.includes('[[tow-hold-cost]]')) {
            const towPrice = await fetchTowPrice();
            const holdCost = towPrice * 2; 
            nextNode.dialogue = nextNode.dialogue.replace(/\[\[tow-hold-cost\]\]/g, holdCost);
          }
          if (nextNode.dialogue.includes('[[tow-prov-dist]]')) {
              nextNode.dialogue = nextNode.dialogue.replace(/\[\[tow-prov-dist\]\]/g, scriptState.towData.provDist);
          }
          if (nextNode.dialogue.includes('[[tow-total-dist]]')) {
              nextNode.dialogue = nextNode.dialogue.replace(/\[\[tow-total-dist\]\]/g, scriptState.towData.totalDist);
          }

          curbsidecurrent.currentCurbsideDialogue = nextNode;
        scriptState.nodeHistory.push(nextNodeId);
        sharedState.updateStateHistory(); // Call to updateStateHistory

        if (nextNode.state && Array.isArray(nextNode.state)) {
          updateState(nextNode.state);
        }
        if (nextNode.actions && Array.isArray(nextNode.actions)) {
          nextNode.actions.forEach(action => {
              switch (action) {
                case 'send-ars-invoice':
                  submitForm();
                  break;
                case 'reset-state':
                  scriptState.resetScriptState();
                  break;
                case 'send-tow-invoice':
                  submitTow();
                  break;
              }
          });
        }
      }
    };

    const startOver = () => {
      resetScriptState();
      curbsidecurrent.currentCurbsideDialogue = curbsidescript.nodes[0];
    };

    const goBack = () => {
      if (scriptState.nodeHistory.length > 1) {
        history.back();
      }
    };

    async function submitForm() {
        if (!scriptState.isSubmitted) {
            scriptState.isSubmitted = true;
            scriptState.responseMessage = `Creating invoice...`;
            let endpoint = '/invoice/';
            if (scriptState.formData.selectedServices.includes("Fuel Delivery")) {
                if (scriptState.formData.comment !== '') {
                    scriptState.formData.comment += '; ';
                }
                scriptState.formData.comment += scriptState.selectedFuel;
            }
            if (scriptState.selectedSide !== []) {
                scriptState.selectedSide.forEach(side => {
                    if (scriptState.formData.comment !== '') {
                        scriptState.formData.comment += '; ';
                    }
                    scriptState.formData.comment += side;
                });
            }
            if (scriptState.hwySide !== '') {
                if (scriptState.formData.comment !== '') {
                    scriptState.formData.comment += '; ';
                }
                scriptState.formData.comment += scriptState.hwySide;
            }
            const payload = {
                make: scriptState.formData.vehicleMake,
                model: scriptState.formData.vehicleModel,
                year: scriptState.formData.vehicleYear,
                color: scriptState.formData.vehicleColor,
                services: scriptState.formData.selectedServices,
                street: scriptState.formData.street,
                city: scriptState.formData.city,
                state: scriptState.formData.state,
                zip: scriptState.formData.zip,
                market: scriptState.formData.market,
                motoristFirst: scriptState.formData.motoristFirstName,
                motoristLast: scriptState.formData.motoristLastName,
                motoristCell: scriptState.formData.motoristCell,
                motoristEmail: scriptState.formData.motoristEmail,
                highway: scriptState.formData.highwayCall,
                googleAddress: scriptState.formData.googleAddress,
                latitude: scriptState.formData.latitude,
                longitude: scriptState.formData.longitude,
                comment: scriptState.formData.comment,
                provider: scriptState.formData.provider,
            };
            
            if (scriptState.scheduledTime !== '') {
                payload.scheduledTime = scriptState.scheduledTime
                endpoint = '/invoice/sched';
            }
            try {
                const response = await axios.post(endpoint, payload);
                console.log("Response received:", response.data);
                if (response.data.valid) {
                    scriptState.responseMessage = `✅ Invoice sent to ${response.data.phone}`;
                } else {
                    scriptState.responseMessage = `❌ Unable to send invoice: ${response.data.error}`;
                }
            } catch (error) {
                console.error("Error in submitForm:", error);
                scriptState.responseMessage = `❌ Error creating invoice: ${error.message}`;
            }
        }
    }

    async function submitTow() {
        if (!scriptState.isSubmitted) {
            scriptState.isSubmitted = true;
            scriptState.responseMessage = `Creating invoice...`;
            let endpoint = '/tow/invoice';
            if (scriptState.towData.towNotes !== []) {
                scriptState.towData.towNotes.forEach(note => {
                    if (scriptState.formData.comment !== '') {
                        scriptState.formData.comment += '; ';
                    }
                    scriptState.formData.comment += note;
                });
            }
            const payload = {
                startLoc: scriptState.towData.startLoc,
                startLat: scriptState.towData.startLat,
                startLon: scriptState.towData.startLon,
                street: scriptState.towData.street,
                city: scriptState.towData.city,
                state: scriptState.towData.state,
                zip: scriptState.towData.locZip,
                destLoc: scriptState.towData.destLoc,
                destLat: scriptState.towData.destLat,
                destLon: scriptState.towData.destLon,
                svcList: scriptState.towData.svcList,
                make: scriptState.formData.vehicleMake,
                model: scriptState.formData.vehicleModel,
                year: scriptState.formData.vehicleYear,
                color: scriptState.formData.vehicleColor,
                motoristFirst: scriptState.formData.motoristFirstName,
                motoristLast: scriptState.formData.motoristLastName,
                motoristCell: scriptState.formData.motoristCell,
                motoristEmail: scriptState.formData.motoristEmail,
                comment: scriptState.formData.comment,
                provider: scriptState.formData.provider
            };
            try {
                const response = await axios.post(endpoint, payload);
                console.log("Response received:", response.data);
                if (response.data.valid) {
                    scriptState.responseMessage = `✅ Invoice sent to ${response.data.phone}`;
                    scriptState.fieldsValid = true;
                } else {
                    scriptState.responseMessage = `❌ Unable to send invoice: ${response.data.error}`;
                }
            } catch (error) {
                console.error("Error in submitForm:", error);
                scriptState.responseMessage = `❌ Error creating invoice: ${error.message}`;
            }
        }
    }

    return { current, curbsidecurrent, updateDialogue, startOver, goBack, scriptState, resetScriptState, submitForm, submitTow, sharedState };
  },

  mounted() {
      window.addEventListener('popstate', this.handlePopstate);
  },
  beforeUnmount() {
      window.removeEventListener('popstate', this.handlePopstate);
  },

  computed: {
    filteredOptions() {
      if (!this.curbsidecurrent.currentCurbsideDialogue || !Array.isArray(this.curbsidecurrent.currentCurbsideDialogue.options)) {
        return [];
      }
      return this.curbsidecurrent.currentCurbsideDialogue.options.filter(this.isOptionAvailable);
    },
  },

  methods: {
      isOptionAvailable(option) {
        if (!option.availability) {
          return true;
        }
        const now = moment().tz('America/Chicago');
        const currentTime = now.format('HHmm');
        const currentTimeInt = parseInt(currentTime, 10);
        const currentDay = now.day();
        let dayType = null;
        if ([1, 2, 3, 4, 5].includes(currentDay)) {
          dayType = option.availability.weekdays;
        } else if ([0, 6].includes(currentDay)) {
          dayType = option.availability.weekends;
        }
        if (dayType && Object.keys(dayType).length === 0) {
          return true;
        }
        if (dayType && dayType.timeGate) {
          const startTime = parseInt(dayType.timeGate[0], 10);
          const endTime = parseInt(dayType.timeGate[1], 10);
          if (startTime < endTime) {
            return currentTimeInt >= startTime && currentTimeInt <= endTime;
          } else {
            return currentTimeInt >= startTime || currentTimeInt <= endTime;
          }
        }
        return false;
      },

      restoreState(previousState) {
          if (previousState) {
              Object.keys(previousState).forEach(key => {
                  scriptState[key] = previousState[key];
              });
          } else {
              console.warn("No state to restore");
          }
      },

      handlePopstate(event) {
          console.log('Popstate event state:', event.state);
          if (event.state && event.state.nodeId) {
              const nodeId = event.state.nodeId;
              const node = this.nodes.find(n => n.id === nodeId);
              if (node) {
                  this.currentNodeId = nodeId;
                  curbsidecurrent.currentCurbsideDialogue = node;
                  if (event.state.state) {
                      this.restoreState(event.state.state);
                  }
              }
          }
      },

      formatDateTime(isoString) {
          const date = new Date(isoString);
          return date.toLocaleString(undefined, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
          });
      }
  }
};
</script>

<style scoped>
.form-container {
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  max-width: 20%; 
  min-width: 600px;
  background-color: #FAF8F8;
  margin: 20px auto;
  padding: 60px;
  padding-top: 10px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  box-shadow: 0 2px 4px hsla(0, 0%, 62%, 0.714);
}

.form-container form {
  text-align: left;
}

.form-container label, 
.form-container input,
.form-container select {
  display: inline;
}

.form-row {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px; /* Adjust as needed */
}

.form-field {
  flex-grow: 1;
}

.dropdown {
    max-width: 100%;
    min-width: 30%;
    position: relative;
}

label {
    white-space: nowrap;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

input[type="text"],
select,
button {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; 
  margin-bottom: 10px;
}

.create-button {
    justify-content: right;
}

.text-wide {
    width: 80%;
}

h1, h2 {
    text-align: center;
}

.button-container {
  text-align: right;
}

button {
  color: white;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
  justify-content: right;
}

.button-enabled {
    background-color: #4682b4;
    color: white;
}

.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
    cursor: not-allowed;
}

.response-message {
    margin-top: 20px;
    color: #333;
    font-size: 18px;
    text-align: center;
}

.invoice-description {
    margin-top: 20px;
    margin: 30px;
    color: #333;
    font-size: 18px;
    text-align: left;
    line-height: 1.8em;
}

.textarea-wide {
    width: 100%;
    height: 80px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.checkbox-group {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.fields-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
}
.fields-container > div {
  display: inline;
}


.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 5px; 
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.custom-checkbox span.checkbox-label {
  pointer-events: none;
}

.custom-checkbox input[type="checkbox"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-checkbox .checkbox-label {
  display: inline;
}

.dialogue-box {
  border-radius: 5px;
  background-color: #4682b4;
  position: relative; 
  padding-left: 60px; 
  color: #fff;
  padding: 20px;
  margin: 10px auto;
  width: 60%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.option-box {
  border-radius: 5px;
  padding: 15px;
  color: #fff;
  margin: 5px auto;
  width: 50%;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  background-color: #99b3c9;
  transition: background-color 0.3s, color 0.3s;
}

.directive-box {
  border-radius: 5px;
  background-color: #006400; /* Dark green background for directives */
  color: #fff;
  padding: 20px;
  margin: 10px auto;
  width: 60%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.dialogue-box::before {
  content: "“";
  font-size: 50px;
  color: #fff;
  position: absolute;
  left: -3px;
  top: -3px;
}
.dialogue-box::after {
  content: "”";
  font-size: 50px;
  color: #fff;
  position: absolute;
  right: -3px;
  bottom: -3px;
}

.end-box {
  border-radius: 5px;
  background-color: #be0404;
  color: #fff;
  padding: 20px;
  margin: 10px auto;
  width: 60%;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.end-box::before {
  content: "“";
  font-size: 50px;
  color: #fff;
  position: absolute;
  left: -3px;
  top: -3px;
}

.option-box:hover {
  background-color: #4682b4;
}

.dialogue-option {
  border-radius: 5px;
  background-color: #99b3c9;
  color: #fff;
  padding: 15px;
  margin: 5px auto;
  width: 50%;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.dialogue-option:hover {
  background-color: #4682b4;
}

.directive-option {
  border-radius: 5px;
  background-color: #006400;
  color: #fff;
  padding: 15px;
  margin: 5px auto;
  width: 50%;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.directive-option:hover {
  background-color: #0f420f;
}

.end-option {
  border-radius: 5px;
  background-color: #be0404;
  color: #fff;
  padding: 15px;
  margin: 5px auto;
  width: 50%;
  border: 1px solid #ccc;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.end-option:hover {
  background-color: #850202;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.response-message {
    margin-top: 20px;
    color: #333;
    font-size: 18px;
    text-align: center;
}

.styled-table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table tbody tr:first-child {
    border-top-left-radius: 5px;
}

.styled-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
}

.styled-table th, .styled-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #99b3c9;
}

.table-title {
    background-color: #4682b4;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}


</style>
