<template>
    <div class="veh-container">
        <div class="form-field">
            <div class="vehicleTitleText">Color</div>
            <input type="text" id="vehicleColor" v-model="vehicleColor" class="color-field" maxlength="10">
        </div>
        <div class="form-field">
            <div class="vehicleTitleText">Make</div>
            <select id="vehicleMake" v-model="vehicleMake" class="dropdown">
                <option v-for="make in vehicleData" :key="make.value" :value="make.value">{{ make.label }}</option>
            </select>
        </div>
        <div v-if="selectedMake" class="form-field">
            <div class="vehicleTitleText">Model</div>
            <select id="vehicleModel" v-model="vehicleModel" class="dropdown">
                <option v-for="model in selectedMake.children" :key="model.value" :value="model.value">{{ model.label }}</option>
            </select>
        </div>
        <div v-if="selectedModel" class="form-field">
            <div class="vehicleTitleText">Year</div>
            <select id="vehicleYear" v-model="vehicleYear" class="dropdown">
                <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
            </select>
        </div>
        <div class="form-field">
            <span class="res-text">
                <span v-if="isResValidating">Validating vehicle...</span>
                <span v-else-if="isResValid === false">Unfortunately we cannot assist with your vehicle</span>
        </span>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { publicState } from  '@/pubstore.js';
import axios from 'axios';

export default {
    props: {
        label: String,
    },
    setup() {
        const vehicleData = computed({
            get: () => publicState.vehicleData,
            set: value => { publicState.vehicleData = value; }
        });
        const vehicleColor = computed({
            get: () => publicState.formData.vehicleColor,
            set: value => { publicState.formData.vehicleColor = value; }
        });
        const vehicleMake = computed({
            get: () => publicState.formData.vehicleMake,
            set: value => { publicState.formData.vehicleMake = value; }
        });
        const vehicleModel = computed({
            get: () => publicState.formData.vehicleModel,
            set: value => { publicState.formData.vehicleModel = value; }
        });
        const vehicleYear = computed({
            get: () => publicState.formData.vehicleYear,
            set: value => { publicState.formData.vehicleYear = value; }
        });
        const isResValid = computed({
            get: () => publicState.isResValid,
            set: value => { publicState.isResValid = value; }
        });
        const isResValidating = computed({
            get: () => publicState.isResValidating,
            set: value => { publicState.isResValidating = value; }
        });
        const selectedServices = computed({
            get: () => publicState.formData.selectedServices,
            set: value => { publicState.formData.selectedServices = value; }
        });
        return {
            vehicleData,
            vehicleColor,
            vehicleMake,
            vehicleModel,
            vehicleYear,
            isResValid,
            isResValidating,
            selectedServices
        };
    },
    mounted() {
        this.fetchVehicleData();
    },
    computed: {
        selectedMake() {
            return publicState.vehicleData.find(make => make.value === publicState.formData.vehicleMake);
        },
        selectedModel() {
            return this.selectedMake ? this.selectedMake.children.find(model => model.value === publicState.formData.vehicleModel) : null;
        }, 
        isFormDataComplete() {
            return publicState.formData.vehicleColor && publicState.formData.vehicleYear && publicState.formData.vehicleMake && publicState.formData.vehicleModel && publicState.formData.selectedServices.length > 0;
        },
        vehicleYearCompute() {
            return publicState.formData.vehicleYear;
        },
        vehicleMakeCompute() {
            return publicState.formData.vehicleMake;
        },
        vehicleModelCompute() {
            return publicState.formData.vehicleModel;
        },
        selectedServicesCompute() {
            return publicState.selectedServices;
        },
        years() {
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let year = currentYear + 1; year >= 1970; year--) {
                years.push(`${year}`);
            }
            return years;
        },
    },
    methods: {
        async fetchVehicleData() {
            try {
                const response = await axios.get('/veh');
                if (response.data.code === "000000") {
                    publicState.vehicleData = response.data.data;
                } else {
                    console.error('Invalid response fetching the vehicle data');
                }
            } catch (error) {
                console.error('There was an error fetching the vehicle data:', error);
            }
        },
        async validateRestrictions() {
            if (this.isFormDataComplete) {
                publicState.isResValidating = true;
                const payload = {
                    make: publicState.formData.vehicleMake.trim(),
                    model: publicState.formData.vehicleModel.trim(),
                    year: publicState.formData.vehicleYear.trim(),
                    services: publicState.formData.selectedServices,
                };
                try {
                    const response = await axios.post('/public/restriction', payload);
                    if (response.data.make === payload.make &&
                        response.data.model === payload.model &&
                        response.data.year === payload.year &&
                        this.arraysEqual(response.data.services, payload.services)) {
                            publicState.isResValid = response.data.valid;
                            publicState.fieldsValid = response.data.valid;
                        }
                } catch (error) {
                    console.error('Error validating restrictions:', error);
                } finally {
                    publicState.isResValidating = false;
                }
            }
        },
        arraysEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;
        }
    },
    watch: {
        vehicleYearCompute() {
            this.validateRestrictions();
        },
        vehicleMakeCompute() {
            this.validateRestrictions();
        },
        vehicleModelCompute() {
            this.validateRestrictions();
        },
        selectedServicesCompute() {
            this.validateRestrictions();
        }
    },
};
</script>

<style scoped>
.veh-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom:10px;
}

.form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.dropdown, .color-field {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.form-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.res-text {
    font-size: 1.2em;
}

.vehicleTitleText {
    margin-bottom: 5px;
}

@media screen and (max: 780px) {
.veh-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.dropdown, .color-field {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
}

.form-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.res-text {
    font-size: 1.2em;
}
    
    .dropdown, .color-field {
    max-width: 50%;
  }
    .vehicleTitleText {
    margin-bottom: 5px;
}
}
</style>
