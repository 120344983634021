<template>
    <div class="map-container">
        <Map
            :center="markerPosition"
            :zoom="10"
            map-type-id="roadmap"
            class="map"
        >
            <Marker
                :position="markerPosition"
                :draggable="true"
                @dragend="onMarkerDragEnd"
            ></Marker>
        </Map>
    </div>
    <div class="form-field">
        <div class="form-field-addr">
            <GMapAutocomplete
                placeholder="Enter motorist location"
                v-model="scriptState.towData.destLoc"
                @place_changed="setPlace"
                ref="autocomplete"
                type="text"
                class="text-wide"
            >
            </GMapAutocomplete>
        </div>
        <span v-if="scriptState.towData.destValidating">⏳ Validating location...</span>
        <span v-else-if="scriptState.towData.destValid === true">✅ Valid destination</span>
        <span v-else-if="scriptState.towData.destValid === false">❌ Invalid destination</span>
    </div>
</template>

<script>
import { computed } from 'vue';
import { Map, Marker, GMapAutocomplete } from '@fawmi/vue-google-maps';
import axios from 'axios';
import { scriptState } from  '@/store.js';

export default {
    props: {
        label: String,
    },
    components: {
        GMapAutocomplete,
        Map,
        Marker,
    },
    setup() {
        return {
            scriptState,
            isDestValidating: computed(() => scriptState.towData.destValidating),
            destValidation: computed(() => scriptState.towData.destValid),
        };
    },

    data() {
        return {
            defaultLocation: {
                lat: 32.7763, 
                lng: -96.7934
            },
        };
    },

    computed: {
        markerPosition() {
            const lat = scriptState.towData.destLat;
            const lng = scriptState.towData.destLon;
            if (this.isValidCoordinate(lat) && this.isValidCoordinate(lng)) {
                return {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                };
            }
            return this.defaultLocation;
        }
    },

    methods: {
        isValidCoordinate(coordinate) {
            return coordinate !== '' && !isNaN(parseFloat(coordinate)) && isFinite(coordinate);
        },

        updateAutocompleteValue(newValue) {
            const autocompleteInput = this.$refs.autocomplete?.$refs.input;
            if (autocompleteInput) {
                autocompleteInput.value = newValue;
            }
        },

        setPlace(place) {
            if (!place || !place.geometry) {
                console.error('No valid place data');
                return;
            }
            let localZip = '';
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case 'postal_code':
                        localZip = component.long_name;
                        break;
                }
            }
            scriptState.towData.locZip = localZip;
            scriptState.towData.destLoc = place.formatted_address;
            this.updateAutocompleteValue(place.formatted_address);
            let latitude, longitude;
            if (typeof place.geometry.location.lat === 'function') {
                latitude = place.geometry.location.lat();
            } else {
                latitude = place.geometry.location.lat;
            }
            if (typeof place.geometry.location.lng === 'function') {
                longitude = place.geometry.location.lng();
            } else {
                longitude = place.geometry.location.lng;
            }
            if (this.isValidCoordinate(latitude) && this.isValidCoordinate(longitude)) {
                scriptState.towData.destLat = latitude;
                scriptState.towData.destLon = longitude;
            }
        },

        onMarkerDragEnd(event) {
            const newLat = event.latLng.lat();
            const newLng = event.latLng.lng();
            this.reverseGeocode(newLat, newLng);
        },

        async reverseGeocode(latitude, longitude) {
            try {
                const response = await fetch('/public/geocode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ latitude, longitude }),
                });
                if (!response.ok) {
                    throw new Error('Reverse geocoding failed');
                }
                const place = await response.json();
                const routeIndex = place.results.findIndex(result => 
                    result.types && (result.types.includes('street_address') || result.types.includes('route'))
                );
                if (routeIndex !== -1) {
                    this.setPlace(place.results[routeIndex]);
                } else {
                    console.error('No result with type "route" found');
                    this.validateDest('');
                }
            } catch (error) {
                console.error('Error in reverse geocoding:', error);
            }
        },

        async validateDest(dest) {
            scriptState.towData.destValidating = true;
            scriptState.towData.destValid = null;
            const payload = {
                startLoc: scriptState.towData.startLoc,
                destLoc: dest,
                zip: scriptState.towData.locZip
            };
            try {
                const response = await axios.post(`/tow/dest`, payload);
                scriptState.towData.destValid = response.data.valid;
                scriptState.fieldsValid = response.data.valid;
            } catch (error) {
                console.error('Error validating zip code:', error);
            }
            scriptState.towData.destValidating = false;
        },
    },
    watch: {
        'scriptState.towData.destLoc': function(loc) {
            if (loc.length > 0) {
                this.validateDest(loc);
            } else {
                scriptState.towData.destValid = null;
            }
        },
    },
};
</script>

<style scoped>
.map-container {
    width: 100%;
    margin-bottom: 20px;
}

.map {
  height: 300px;
  width: 100%;
}

.form-field {
    align-items: center;
    max-width: 100%;
    min-width: 600px;
    display: inline-flex;
}

.form-field-addr {
    flex-grow: 1;
    width: 100%;
    margin-left: 10%;
    display: inline-flex;
    justify-content: space-between;
}

.text-wide {
    width: 100%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
}
</style>