<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-title">ARS Retail Portal</div>
      <div>
        <span class="ms-button" @click="loginWithAzure">
          <img src="/mslogin.svg">
        </span>
      </div>
      <div>
        <button class="gsi-material-button" @click="loginWithGoogle">
          <div class="gsi-material-button-state"></div>
          <div class="gsi-material-button-content-wrapper">
            <div class="gsi-material-button-icon">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                <path fill="none" d="M0 0h48v48H0z"></path>
              </svg>
            </div>
            <span class="gsi-material-button-contents">Sign in with Google</span>
            <span style="display: none;">Sign in with Google</span>
          </div>
        </button>
      </div>

      <div>
        <form @submit.prevent="submitGuestEmail">
          <input type="email" v-model="guestEmail" placeholder="Guest email" required class="guest-email-input">
          <button type="submit" :disabled="emailClicked === true" class="guest-submit-button">Send</button>
        </form>
        <div v-if="guestLoginStatus">
          <span v-if="guestLoginStatus === 200">✅ Login link sent</span>
          <span v-else>❌ Error: {{ guestLoginError }}</span>
        </div>
      </div>

      <!-- Developer login form -->
      <div v-if="isDeveloperMode">
        <!-- <div> -->
        <form action="http://localhost:8080/auth" method="post">
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" class="form-control" id="username" name="username" placeholder="Enter username" required>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" name="password" placeholder="Enter password" required>
          </div>

          <button type="submit" class="btn btn-primary">Login</button>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      guestEmail: '',
      guestLoginStatus: null,
      guestLoginError: '',
      emailClicked: false
    };
  },
  computed: {
    isDeveloperMode() {
      return process.env.NODE_ENV === 'development';
    }
  },
  methods: {
    loginWithAzure() {
      window.location.href = '/login/azure';
    },
    loginWithGoogle() {
      window.location.href = '/login/google';
    },
    async submitGuestEmail() {
      this.emailClicked = true;
      const trimmedEmail = this.guestEmail.trim().toLowerCase();
      try {
        const response = await fetch('/guest/req', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: trimmedEmail })
        });
        this.guestLoginStatus = response.status;
        if (response.status !== 200) {
          this.emailClicked = false;
          const errorText = await response.text();
          this.guestLoginStatus = response.status;
          this.guestLoginError = errorText;
        }
      } catch (error) {
        this.guestLoginStatus = 500;
        this.emailClicked = false;
        this.guestLoginError = error.message;
      }
    }
  }
}
</script>

<style scoped>

.login-page {
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center; 
    align-items: center; 
    background-color: #f5f5f5;
}

.login-container {
    margin: 0; 
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    width: 300px;
}

.login-title {
    font-family: 'Victor Mono', monospace;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.login-button {
    background-color: #0078d4;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    border-radius: 4px;
    font-size: 16px;
    transition: background-color 0.3s;
}

.ms-button {
  cursor: pointer;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  width: auto;
}

.login-button:hover {
    background-color: #005a9e;
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.guest-email-input {
  width: calc(50%);
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.guest-submit-button {
  background-color: #3b8dcc;
  color: #fff;
  border: none;
  padding: 10px 10px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.guest-submit-button:disabled {
  background-color: #ddd;
}

.guest-submit-button:hover:not(:disabled) {
  background-color: #005a9e;
}
</style>
