<template>
    <div class="form-field">
        <input type="datetime-local" :min="minDateTime" :max="maxDateTime" v-model="localDateTime" @change="validateTime" class="datetime-picker"/>
    </div>
    <div v-if="timeError" class="error-message">Please select a time between 8 AM and 6 PM.</div>
</template>

<script>
import { ref, computed } from 'vue';
import { scriptState } from '@/store.js';

export default {
  setup() {
    const localDateTime = ref('');
    const timeError = ref(false);
    const minDateTime = computed(() => {
        let now = new Date();
        let formattedNow = now.toISOString().substring(0, 16);
        return formattedNow;
    });

    const validateTime = () => {
        const selectedTime = new Date(localDateTime.value);
        const hour = selectedTime.getHours();
        if (hour < 8 || hour >= 18) {
            timeError.value = true;
            return;
        }
        timeError.value = false;
        scriptState.fieldsValid = true;
        scriptState.scheduledTime = selectedTime.toISOString();
    };

    return {
        localDateTime,
        timeError,
        minDateTime,
        validateTime,
    };
  },
};
</script>

<style scoped>
.datetime-picker {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.error-message {
  color: #a00606;
  margin-top: 10px;
}
</style>
