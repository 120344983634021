<template>
  <div class="form-container">
    <h1>Retail job history</h1>
    <hr />
    <div class="search-bar">
      <input v-model="searchParams.motorist_first" placeholder="First Name">
      <input v-model="searchParams.motorist_last" placeholder="Last Name">
      <input v-model="searchParams.motorist_cell" placeholder="Cell">
      <input v-model="searchParams.google_address" placeholder="Address">
      <input v-model="searchParams.market" placeholder="Market">
      <input v-model="searchParams.wo" placeholder="WO">
      <input v-model="searchParams.submitter" placeholder="Dispatcher">
      <input v-model="searchParams.provider" placeholder="Provider">
      <button @click="performSearch">Search</button>
    </div>
    <div>
      <div v-for="item in custData" :key="item.id" class="item">
        <code>{{ formatToLocalTime(item.timestamp) }}</code><br>
        <button @click="toggle(item.id)" class="collapsible">
          <strong>{{ visibleRows[item.id] ? '↑' : '↓' }}
            <span v-if="item.paid === 'true'">💰</span>
            <span v-else-if="item.paid === null">❌</span> [{{ item.provider }}]
          </strong> {{ item.service }} @ {{ item.googleAddress }}
        </button>
        <div v-if="visibleRows.includes(item.id)" class="content">
          <div class="info-box">
            <h3>Customer</h3>
            <p>{{ item.motoristFirst }} {{ item.motoristLast }}</p>
            <p>{{ item.motoristCell }}</p>
            <p v-if="item.motoristEmail !== ''">{{ item.motoristEmail }}</p>
            <p>{{ item.googleAddress }} ({{ item.latitude }}/{{ item.longitude }})</p>
            <p v-if="item.provider === 'ARS'">{{ item.market }}</p>
            <p v-if="item.highway === 'yes'">Customer located on highway</p>
            <p v-if="item.comment !== ''">{{ item.comments }}</p>
          </div>
          <div class="info-box">
            <h3>Service</h3>
            <p>{{ item.service }} for {{ item.year }} {{ item.make }} {{ item.model }} <span v-if="item.color !== 'unknown'">({{ item.color }})</span></p>
            <p>
              <span v-if="item.submitter !== 'selfservice'">Created by {{ item.submitter }}</span>
              <span v-if="item.submitter === 'selfservice'">Customer self-service</span>
            </p>
            <p v-if="item.destAddress !== null">Destination: {{ item.destAddress }} ({{ item.destLat }}/{{ item.destLon }}) </p>
            <p v-if="item.wo !== null">WO <a :href="'https://gate.ars-janus.com/workOrder/detail/'+item.wo+'/overview'">{{ item.wo }}</a></p>
            <p>Created {{ formatToLocalTime(item.timestamp) }}</p>
            <p v-if="item.scheduledTime !== null">Scheduled for {{ formatToLocalTime(item.scheduledTime) }}</p>
            <p>Provider: {{ item.provider }}</p>
            <p>${{ item.paymentAmount / 100 }}</p>
            <p v-if="item.paid === null"><a :href="item.paymentLink">Invoice link</a> (not paid)</p>
            <p v-if="item.paid === 'true'"><a :href="item.receipt">Receipt link</a></p>
            <p v-if="item.eta">Provider ETA: {{ item.eta }} minutes</p>
          </div>
        </div>
      </div>
      <button @click="refreshData">Reload</button>
      <button @click="loadMore">Load more</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const custData = ref([]);
const visibleRows = ref([]);
const start = ref(0);
const itemCount = ref(0);
const searchParams = ref({
  motoristFirst: '',
  motoristLast: '',
  motoristCell: '',
  googleAddress: '',
  market: '',
  wo: '',
});

const performSearch = async () => {
  const query = new URLSearchParams(
    Object.entries(searchParams.value).filter(([, value]) => value)
  ).toString();
  try {
    const response = await fetch(`/db/search?${query}`);
    const data = await response.json();
    custData.value = [...data.data];
  } catch (error) {
    console.error('Error fetching search data:', error);
  }
};

const fetchData = async (reset = false) => {
  if (reset) {
    custData.value = [];
    start.value = 0;
  }
  
  try {
    const response = await fetch(`/db/cust?start=${start.value}`);
    const data = await response.json();
    if (reset) {
      custData.value = data.data;
    } else {
      custData.value.push(...data.data);
    }
    start.value += data.data.length;
    itemCount.value += data.data.length;
  } catch (error) {
    console.error('Error fetching customer data:', error);
  }
};

const toggle = (id) => {
  const index = visibleRows.value.indexOf(id);
  if (index > -1) {
    visibleRows.value.splice(index, 1);
  } else {
    visibleRows.value.push(id);
  }
};

const formatToLocalTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short'
  });
};

const loadMore = () => {
  fetchData();
};

const refreshData = () => {
  fetchData(true);
};

onMounted(() => fetchData(true));
</script>

<style scoped>
.collapsible {
  background-color: #f9f9f9;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}

.info-box {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  line-height: 1em;
}

.info-box p {
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 1.2em;
}


.item {
  border: 1px solid #ddd;
}

.item .content {
  display: block;
}

h1, h2 {
    text-align: center;
}

.form-container {
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  max-width: 20%; 
  min-width: 600px;
  background-color: #FAF8F8;
  margin: 20px auto;
  padding: 60px;
  padding-top: 10px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  box-shadow: 0 2px 4px hsla(0, 0%, 62%, 0.714);
}

.form-container form {
  text-align: left;
}

.form-container label, 
.form-container input,
.form-container select {
  display: inline;
}

.search-bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}

.search-bar input {
  flex-grow: 1;
  max-width: calc(50% - 20px);
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-bar button {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #fff;
  background-color: #7EB2CF;
}

</style>
