<template>
  <div class="form-container">
    <h1>Guest users</h1>
    <hr />
    <form @submit.prevent="addGuest" class="form-group">
      <div class="input-group">
        <input v-model="newGuest.name" id="name" placeholder="Name" required />
      </div>
      <div class="input-group">
        <input v-model="newGuest.email" id="email" placeholder="Email" required />
      </div>
      <div class="input-group">
        <select v-model="selectedCompany" @change="fetchGuests" required>
          <option value="guests">CSOS</option>
          <option value="towtrax">Towtrax</option>
          <option value="jackrabbit">Jackrabbit</option>
          <option value="safetravels">SafeTravels</option>
        </select>
      </div>
      <button type="submit">Add Guest</button>
      <input type="file" @change="uploadCSV" accept=".csv" />
    </form>
    <hr />
    <div v-if="error" class="error">{{ error }}</div>
    <table>
      <thead>
        <tr>
          <th @click="sort('name')">
            Name
            <span v-if="sortKey === 'name'">
              <span v-if="sortOrder === 'asc'">🔼</span>
              <span v-else>🔽</span>
            </span>
          </th>
          <th @click="sort('email')">
            Email
            <span v-if="sortKey === 'email'">
              <span v-if="sortOrder === 'asc'">🔼</span>
              <span v-else>🔽</span>
            </span>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="guest in sortedGuests" :key="guest.email">
          <td>{{ guest.name }}</td>
          <td>{{ guest.email }}</td>
          <td><button @click="deleteGuest(guest.email)">❌</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'GuestUsers',
  data() {
    return {
      guests: [],
      newGuest: {
        name: '',
        email: ''
      },
      selectedCompany: 'guests',
      error: '',
      sortKey: '',
      sortOrder: 'asc'
    };
  },
  created() {
    this.fetchGuests();
  },
  computed: {
    sortedGuests() {
      if (!this.sortKey) return this.guests;
      return [...this.guests].sort((a, b) => {
        const result =
          a[this.sortKey].localeCompare(b[this.sortKey], undefined, { sensitivity: 'base' });
        return this.sortOrder === 'asc' ? result : -result;
      });
    }
  },
  methods: {
    sort(key) {
      if (this.sortKey === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortKey = key;
        this.sortOrder = 'asc';
      }
    },
    async fetchGuests() {
      try {
        const response = await axios.get(`/guest/admin/${this.selectedCompany}`);
        this.guests = response.data || [];
      } catch (error) {
        console.error('Error fetching guests:', error);
        this.error = 'Error fetching guests';
        this.guests = [];
      }
    },
    async addGuest() {
      try {
        const response = await axios.post(`/guest/admin/${this.selectedCompany}`, [this.newGuest]);
        if (response.status === 200) {
          this.fetchGuests();
          this.newGuest = { name: '', email: '' };
        } else {
          this.error = 'Error adding guest';
        }
      } catch (error) {
        console.error('Error adding guest:', error);
        this.error = error.response?.data || 'Unknown error occurred';
      }
    },
    async deleteGuest(email) {
      try {
        const response = await axios.delete(`/guest/admin/${this.selectedCompany}`, {
          data: [{ email }]
        });
        if (response.status === 200) {
          this.fetchGuests();
        } else {
          this.error = 'Error deleting guest';
        }
      } catch (error) {
        console.error('Error deleting guest:', error);
        this.error = error.response?.data || 'Unknown error occurred';
      }
    },
    async uploadCSV(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const csv = e.target.result;
        const data = this.parseCSV(csv);
        try {
          const response = await axios.post(`/guest/admin/${this.selectedCompany}`, data);
          if (response.status === 200) {
            this.fetchGuests();
          } else {
            this.error = 'Error uploading CSV';
          }
        } catch (error) {
          console.error('Error uploading CSV:', error);
          this.error = error.response?.data || 'Unknown error occurred';
        }
      };
      reader.readAsText(file);
    },
    parseCSV(csv) {
      const lines = csv.split('\n');
      const result = [];
      const headers = lines[0].split(',').map((header) => header.trim().toLowerCase());
      const regex = /(".*?"|[^",\s]+)(?=\s*,|\s*$)/g;
      for (let i = 1; i < lines.length; i++) {
        const line = lines[i].match(regex);
        if (line && line.length === headers.length) {
          const entry = {};
          for (let j = 0; j < headers.length; j++) {
            entry[headers[j]] = line[j].replace(/(^"|"$)/g, '').trim();
          }
          if (entry.name && entry.email) {
            result.push(entry);
          }
        }
      }
      return result;
    }
  }
};
</script>

<style scoped>
.form-container {
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  max-width: 80%;
  min-width: 600px;
  background-color: #FAF8F8;
  margin: 20px auto;
  padding: 60px;
  padding-top: 10px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  box-shadow: 0 2px 4px hsla(0, 0%, 62%, 0.714);
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-group {
  flex-grow: 1;
  max-width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
}

.input-group input, .input-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button[type="submit"], button[type="button"] {
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: #fff;
  background-color: #7EB2CF;
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}

.error {
  color: red;
  margin-top: 20px;
}
</style>
