<template>
    <div class="map-container">
        <Map
            :center="markerPosition"
            :zoom="10"
            map-type-id="roadmap"
            class="map"
        >
            <Marker
                :position="markerPosition"
                :draggable="true"
                @dragend="onMarkerDragEnd"
            ></Marker>
        </Map>
    </div>
    <div class="form-field">
        <div class="form-field-addr">
            <GMapAutocomplete
                placeholder="Enter motorist location"
                v-model="scriptState.formData.googleAddress"
                @place_changed="setPlace"
                ref="autocomplete"
                type="text"
                class="text-wide"
            >
            </GMapAutocomplete>
        </div>
        <span v-if="scriptState.isZipValidating">⏳ Validating location...</span>
        <span v-else-if="scriptState.zipValidationResult === true">✅ {{ scriptState.formData.market }}</span>
        <span v-else-if="scriptState.zipValidationResult === false && scriptState.provider === 'ADS'">Dispatch to Allied</span>
        <span v-else-if="scriptState.zipValidationResult === false">❌ Out of area</span>
        <span v-else-if="scriptState.formData.city !== '' && scriptState.formData.zip === ''">❌ Address needs a zip</span>
    </div>
    <div v-if="scriptState.zipValidationResult">
        <MarketField />
    </div>
    <hr />
    <div class="form-row">
        Send a link to the customer to automatically detect their location
        <div class="form-field">
            <input type="tel" v-model="scriptState.formData.motoristCell" placeholder="Phone number (optional)" pattern="\d*" class="text-med" />
        </div>
        <div class="button-container">
            <button @click="resetLocLinkData" class="create-button" :class="'button-enabled'">
                Reset location link
            </button>
            <button @click="submitLocLink"
                :disabled="!isLocLinkValid || isSubmitted"
                :class="{'button-enabled': isLocLinkValid && !isLocLinkSubmitted, 'button-disabled': !isLocLinkValid || isLocLinkSubmitted}"
                class="create-button">
                Send location link
            </button>
        </div>
        <div v-if="locLinkSend !== ''">{{ locLinkSend }}</div>
        <div v-if="locLinkRes !== ''">{{ locLinkRes }}</div>
    </div>
</template>

<script>
import { computed, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import { Map, Marker, GMapAutocomplete } from '@fawmi/vue-google-maps';
import axios from 'axios';
import { scriptState, webSocketAPI } from  '@/store.js';
import MarketField from '@/components/form/MarketField.vue';

export default {
    props: {
        label: String,
    },
    components: {
        GMapAutocomplete,
        MarketField,
        Map,
        Marker,
    },
    setup() {
        const linkData = ref({
            wo: '',
            phoneNum: scriptState.formData.motoristCell.value,
        });
        watch(() => scriptState.formData.motoristCell, (newVal) => {
            linkData.value.phoneNum = newVal;
        });
        watch(() => scriptState.formData.googleAddress, (newAddress) => {
            if (autocomplete.value && autocomplete.value.$refs.input) {
                autocomplete.value.$refs.input.value = newAddress;
            }
        });
        onMounted(() => {
        if (autocomplete.value && autocomplete.value.$refs.input && scriptState.formData.googleAddress) {
            autocomplete.value.$refs.input.value = scriptState.formData.googleAddress;
        }
        });
        const autocomplete = ref(null);
        const locLinkSend = ref('');
        const locLinkRes = ref('');
        const locLinkSlug = ref('');
        const isLocLinkSubmitted = ref(false);

        const validateZip = async (zipCode) => {
            scriptState.isZipValidating = true;
            scriptState.zipValidationResult = null;
            try {
                const response = await axios.get(`/zip?zip=${zipCode}`);
                scriptState.zipValidationResult = response.data.valid;
                scriptState.fieldsValid = response.data.valid;
                // scriptState.fieldsValid = true;
                scriptState.formData.market = response.data.market;
                scriptState.formData.provider = response.data.provider;
            } catch (error) {
                console.error('Error validating zip code:', error);
                scriptState.zipValidationResult = `Error validating zip ${zipCode}: ${error}`;
            }
            scriptState.isZipValidating = false;
        };

        const updateAutocompleteValue = (newValue) => {
            if (autocomplete.value) {
                const inputElement = autocomplete.value.$el.querySelector('input');
                if (inputElement) inputElement.value = newValue;
            }
        };
        
        const isValidCoordinate = (coordinate) => {
            return coordinate !== '' && !isNaN(parseFloat(coordinate)) && isFinite(coordinate);
        };

        const setPlace = (place) => {
            if (!place || !place.geometry) {
                console.error('No valid place data');
                return;
            }
            scriptState.formData.street = '';
            scriptState.formData.city = '';
            scriptState.formData.state = '';
            scriptState.formData.zip = '';
            scriptState.formData.googleAddress = '';
            scriptState.formData.latitude = '';
            scriptState.formData.longitude = '';
            let localStreet = '';
            let localCity = '';
            let localState = '';
            let localZip = '';
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case 'street_number':
                        localStreet = `${component.long_name} ${localStreet}`;
                        break;
                    case 'route':
                        localStreet += component.short_name;
                        break;
                    case 'locality':
                        localCity = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        localState = component.short_name;
                        break;
                    case 'postal_code':
                        localZip = component.long_name;
                        break;
                }
            }
            scriptState.formData.street = localStreet;
            scriptState.formData.city = localCity;
            scriptState.formData.state = localState;
            scriptState.formData.zip = localZip;
            scriptState.formData.googleAddress = place.formatted_address;
            updateAutocompleteValue(place.formatted_address);
            let latitude, longitude;
            if (typeof place.geometry.location.lat === 'function') {
                latitude = place.geometry.location.lat();
            } else {
                latitude = place.geometry.location.lat;
            }
            if (typeof place.geometry.location.lng === 'function') {
                longitude = place.geometry.location.lng();
            } else {
                longitude = place.geometry.location.lng;
            }
            if (isValidCoordinate(latitude) && isValidCoordinate(longitude)) {
                scriptState.formData.latitude = latitude;
                scriptState.formData.longitude = longitude;
            }
        };

        const reverseGeocode = async (latitude, longitude) => {
            try {
                const response = await fetch('/public/geocode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ latitude, longitude }),
                });
                if (!response.ok) {
                    throw new Error('Reverse geocoding failed');
                }
                const place = await response.json();
                let preferredIndex = place.results.findIndex(result => 
                    result.types.includes('street_address')
                );
                if (preferredIndex === -1) {
                    preferredIndex = place.results.findIndex(result => 
                        result.types.includes('route')
                    );
                }
                if (preferredIndex !== -1) {
                    const result = place.results[preferredIndex];
                    scriptState.formData.googleAddress = result.formatted_address;
                    setPlace(result);
                } else {
                    console.error('No preferred address type found');
                    validateZip('');
                }
                scriptState.formData.latitude = latitude;
                scriptState.formData.longitude = longitude;
            } catch (error) {
                console.error('Error in reverse geocoding:', error);
            }
        }

        const handleMessageReceived = (data) => {
            switch (data.topic) {
                case locLinkSlug.value:
                    console.log(`Processing ${data.content.slug} (${data.content.latitude}/${data.content.longitude})`)
                    if ((locLinkSlug.value !== '') && (data.content.latitude) && (data.content.longitude)) {
                        locLinkRes.value = `Customer location detected as ${data.content.address} (${data.content.latitude}/${data.content.longitude})`;
                        reverseGeocode(data.content.latitude,data.content.longitude);
                        scriptState.formData.latitude = data.content.latitude;
                        scriptState.formData.longitude = data.content.longitude;
                    }
                    break;
                default:
                    break;
            }
        };
        
        onMounted(() => {
            if (!webSocketAPI.isConnected()) {
                webSocketAPI.connectWebSocket();
            }
            webSocketAPI.on('messageReceived', handleMessageReceived);
        });
        onBeforeUnmount(() => {
            webSocketAPI.off('messageReceived', handleMessageReceived);
        });

        async function submitLocLink() {
            if (this.isLocLinkValid && !this.isSubmitted) {
                this.isSubmitted = true;
                this.locLinkSend = '';
                try {
                    const res = await axios.post('/location/link', linkData.value);
                    if (res.status === 200) {
                        const result = res.data;
                    if (result.valid) {
                        this.locLinkSend = `☑️ Link sent to ${result.phone}`;
                        this.locLinkSlug = res.data.slug;
                        console.log(`Subscribing to location slug ${this.locLinkSlug}`)
                        this.locLinkRes = `Waiting for response...`;
                        var payload = {
                            action: 'subscribeLoc',
                            content: {
                                phone: scriptState.formData.motoristCell,
                            }
                        };
                        webSocketAPI.send(JSON.stringify(payload));
                    } else {
                        this.locLinkSend = result.error;
                    }
                    } else {
                        this.locLinkSend = '❌ An error occurred. Please try again.';
                    }
                } catch (error) {
                    if (error.response) {
                        this.locLinkSend = error.response.data.message || '❌ An error occurred. Please try again.';
                    } else if (error.request) {
                        this.locLinkSend = '❌ No response from server. Please check your network connection.';
                    } else {
                        this.locLinkSend = '❌ Error: ' + error.message;
                    }
                    return
                }
            }
        }
        const isLocLinkValid = computed(() => {
            return scriptState.formData.motoristCell >= 10;
        });
        return {
            scriptState,
            locLinkSend,
            locLinkRes,
            linkData,
            locLinkSlug,
            isLocLinkSubmitted,
            isLocLinkValid,
            submitLocLink,
            handleMessageReceived,
            reverseGeocode,
            validateZip,
            setPlace,
            isValidCoordinate,
            updateAutocompleteValue,
            autocomplete,
            isZipValidating: computed(() => scriptState.isZipValidating),
            zipValidationResult: computed(() => scriptState.zipValidationResult),
        };
    },

    data() {
        return {
            defaultLocation: {
                lat: 32.7763, 
                lng: -96.7934
            },
        };
    },

    mounted() {
        webSocketAPI.on('messageReceived', this.handleMessageReceived);
    },

    beforeUnmount() {
        webSocketAPI.off('messageReceived', this.handleMessageReceived);
    },

    computed: {
        markerPosition() {
            const lat = scriptState.formData.latitude;
            const lng = scriptState.formData.longitude;
            if (this.isValidCoordinate(lat) && this.isValidCoordinate(lng)) {
                return {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                };
            }
            return this.defaultLocation;
        }
    },

    methods: {

        onMarkerDragEnd(event) {
            const newLat = event.latLng.lat();
            const newLng = event.latLng.lng();
            this.reverseGeocode(newLat, newLng);
        },

        resetLocLinkData() {
            scriptState.formData.motoristCell = '';
            this.isLocLinkSubmitted = false;
            this.locLinkRes = '';
            this.locLinkSend = '';
        }
    },
    watch: {
        'scriptState.formData.zip': function(newZip) {
            if (newZip.length === 5 && /^\d+$/.test(newZip)) {
                this.validateZip(newZip);
            } else {
                scriptState.zipValidationResult = null;
            }
        },
    },
}
</script>

<style scoped>
.map-container {
    width: 100%;
    margin-bottom: 20px;
}

.map {
  height: 300px;
  width: 100%;
}

.form-field {
    align-items: center;
    max-width: 100%;
    min-width: 600px;
    display: inline-flex;
}

.form-field-addr {
    flex-grow: 1;
    width: 100%;
    margin-left: 10%;
    display: inline-flex;
    justify-content: space-between;
}

.text-wide {
    width: 100%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.text-med {
    width: 60%;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

button {
  color: white;
  cursor: pointer;
  border: none;
  padding: 15px;
  transition: background-color 0.3s;
  justify-content: right;
}

.button-enabled {
    background-color: #4682b4;
    padding: 15px;
    color: white;
}

.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
    padding: 15px;
    cursor: not-allowed;
}

.create-button, .fetch-button {
    justify-content: right;
}

input[type="text"], input[type="tel"], select, button {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
</style>