<template>
    <div class="sched-container">
        <div class="form-row">
            <div class="form-field">
                <label for="datePicker"></label>
                <input type="date" id="datePicker" class="datetime-picker" v-model="selectedDate" :min="today" />
            </div>
            <div class="form-field">
                <label for="hourPicker"></label>
                <select id="hourPicker" v-model="selectedHour">
                    <option disabled value="" selected>Select Hour</option>
                    <option v-for="hour in allowedHours" :key="hour" :value="hour">
                        {{ hour }}:00
                    </option>
                </select>
            </div>
            <div class="form-field">
                <label for="minutePicker"></label>
                <select id="minutePicker" v-model="selectedMinute">
                    <option disabled value="" selected>Select Minute</option>
                    <option v-for="minute in allowedMinutes" :key="minute" :value="minute">
                        {{ minute }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { publicState } from '@/pubstore.js';

export default {
    setup() {
        const selectedDate = ref('');
        const selectedHour = ref('');
        const selectedMinute = ref('');
        const today = new Date().toISOString().split('T')[0];
        const currentHour = new Date().getHours();
        const currentMinute = new Date().getMinutes();

        const allowedHours = computed(() => {
            const hours = [];
            let startHour = 8;
            if (selectedDate.value === today && currentHour >= 8 && currentHour < 17) {
                startHour = currentHour + 1;
            }
            for (let hour = startHour; hour <= 17; hour++) {
                hours.push(hour.toString().padStart(2, '0'));
            }
            return hours;
        });

        const allowedMinutes = computed(() => {
            const minutes = [];
            let startMinute = 0;
            if (selectedDate.value === today && parseInt(selectedHour.value) === currentHour) {
                startMinute = Math.ceil((currentMinute + 1) / 10) * 10;
            }
            for (let minute = startMinute; minute < 60; minute += 10) {
                minutes.push(minute.toString().padStart(2, '0'));
            }
            if (startMinute > 50) {
                minutes.shift();
            }
            return minutes;
        });

        watch([selectedDate, selectedHour, selectedMinute], ([newDate, newHour, newMinute]) => {
            if (newDate && newHour !== '' && newMinute !== '') {
                const [year, month, day] = newDate.split('-').map(num => parseInt(num, 10));
                const adjustedMonth = month - 1;
                let hour = parseInt(newHour, 10);
                const dateTime = new Date();
                dateTime.setFullYear(year, adjustedMonth, day);
                dateTime.setHours(hour, parseInt(newMinute), 0, 0);
                publicState.fieldsValid = true;
                publicState.scheduledTime = dateTime.toISOString();
            }
        }, { immediate: true });


        return {
            selectedDate,
            selectedHour,
            selectedMinute,
            allowedHours,
            allowedMinutes,
            today,
        };
    },
};
</script>


<style scoped>
.sched-container {
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 10px;
}

.datetime-picker, .form-field select {
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    width: 100%;
}

.datetime-picker#datePicker {
    width: calc(100% - 22px); /* or specify the desired width */
}

.form-row {
    justify-content: flex-start;
    align-items: center;
}

.form-field {
    flex-grow: 0;
    flex-basis: none;
    font-size: 16px;
}

.error-message {
    color: #a00606;
    margin-top: 10px;
}
</style>