<template>
    <div class="form-field">
        <div class="form-row">
            <button @click="submitForm"
                    :class="{'button-enabled': !isSubmitted, 'button-disabled': isSubmitted}"
                    class="create-button">
                Send invoice
            </button>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { scriptState } from  '@/store.js';
import axios from 'axios';
export default {
    setup() {
        const isSubmitted = computed({
            get: () => scriptState.isSubmitted,
            set: value => { scriptState.isSubmitted = value; }
        });
        return {
            isSubmitted
        };
    },
    props: {
        label: String,
    },
    methods: {
        async submitForm() {
            if (!scriptState.isSubmitted) {
                let endpoint = '/invoice/';
                scriptState.isSubmitted = true;
                scriptState.responseMessage = `Creating invoice...`;
                if (scriptState.formData.selectedServices.includes("Fuel Delivery")) {
                    if (scriptState.formData.comment !== '') {
                        scriptState.formData.comment += '; ';
                    }
                    scriptState.formData.comment += scriptState.selectedFuel;
                }
                if (scriptState.selectedSide.length > 0) {
                    scriptState.selectedSide.forEach(side => {
                        if (scriptState.formData.comment !== '') {
                            scriptState.formData.comment += '; ';
                        }
                        scriptState.formData.comment += side.value;
                    });
                }
                if (scriptState.hwySide !== '') {
                    if (scriptState.formData.comment !== '') {
                        scriptState.formData.comment += '; ';
                    }
                    scriptState.formData.comment += scriptState.hwySide;
                }
                const payload = {
                    make: scriptState.formData.vehicleMake,
                    model: scriptState.formData.vehicleModel,
                    year: scriptState.formData.vehicleYear,
                    color: scriptState.formData.vehicleColor,
                    services: scriptState.formData.selectedServices,
                    street: scriptState.formData.street,
                    city: scriptState.formData.city,
                    state: scriptState.formData.state,
                    zip: scriptState.formData.zip,
                    market: scriptState.formData.market,
                    motoristFirst: scriptState.formData.motoristFirstName,
                    motoristLast: scriptState.formData.motoristLastName,
                    motoristCell: scriptState.formData.motoristCell,
                    motoristEmail: scriptState.formData.motoristEmail,
                    highway: scriptState.formData.highwayCall,
                    googleAddress: scriptState.formData.googleAddress,
                    latitude: scriptState.formData.latitude,
                    longitude: scriptState.formData.longitude,
                    comment: scriptState.formData.comment,
                    provider: scriptState.formData.provider,
                };
                if (scriptState.scheduledTime !== '') {
                    endpoint = '/invoice/sched';
                    payload.scheduledTime = scriptState.scheduledTime;
                }
                try {
                    const response = await axios.post(endpoint, payload);
                    console.log("Response received:", response.data);
                    if (response.data.valid) {
                        scriptState.responseMessage = `✅ Invoice sent to ${response.data.phone}`;
                    } else {
                        scriptState.responseMessage = `❌ Unable to send invoice: ${response.data.error}`;
                    }
                } catch (error) {
                    console.error("Error in submitForm:", error);
                    scriptState.responseMessage = `❌ Error creating invoice: ${error.message}`;
                }
            }
        }
    }
}
</script>

<style scoped>
body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.invoice-container {
  flex: 1;
}

.form-row {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.form-field {
    font-size: 16px;
    align-items: center;
}

button {
    color: #fff;
    text-align: center;
    background-color: #000000;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6em;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
    box-shadow: 0 20px 30px -10px rgba(24, 16, 99, .36);
    width: 100%;
}

.button-enabled {
    background-color: #000000;
    padding: 15px;
    color: white;
}

.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
    padding: 15px;
    cursor: not-allowed;
}

</style>