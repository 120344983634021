<template>
  <Header />
    <div class="centered-container">
      <div class="centered-box">
        <h1 class="titleText">Success!</h1>
        <p class="body">Expect an automated text message shortly with an ETA of arrival after a Technician is dispatched to your location. If you have any questions or need to update our team with new information, please reach out to a Dispatcher at <a href="tel:+18339424008">+1 (833) 942-4008</a></p>
      </div>
    </div>
  </template>
  
  <script>
  import Header from '@/components/public/AppHeader.vue';
  
  export default {
    name: 'SuccessView',
    components: { Header}
  };
  </script>
  
  <style scoped>
  .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background: #f9f9f9;
  }
 
  .centered-box {
    text-align: center;
    padding: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0,0,0,.1);
    border-radius: 15px;
    max-width: 600px;
    width: 100%;
    box-sizing: border-box;
}
  
.titleText {
    font-size: 2em;
    font-weight: 800;
    font-family: 'Prompt', sans-serif;
    display: block;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    height: 20px;
    padding-top: 20px;
    padding-bottom: 15px;
    background-color: #48a8dc;
  }

  body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    background-color: #7eb2cf;
  }

@media (max-width: 768px) {
  .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 50px;
      height: 25px;
      padding-top: 25px;
      padding-bottom: 20px;
    }

    .body {
    margin-top: 7px;
    text-align: left;
    font-size: 1.3em;
    }

    .titleText {
    font-size: 2.2em;
    font-weight: 800;
    text-align: left;
    font-family: Prompt, sans-serif;
    display: flow;
    margin-top: 0;
    margin-bottom: 2.5px;
    }
  
  .centered-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    background: #f9f9f9;
  }

  .centered-box {
    display: flex;
    flex-direction: column;
    width: auto;
    position: relative;
    height: auto;
    flex-grow: 1;
    margin-top: 71px;
    background-color: #fff;
    padding: 20px;
    box-shadow: none; 
    border-radius: 0; 
  }
}
  </style>
  