<template>
    <div class="form-field">
        <div class="form-field-addr">
            <GMapAutocomplete
                placeholder="Look up address"
                v-model="mapState.googleAddress"
                @place_changed="setPlace"
                ref="autocomplete"
                type="text"
                class="text-wide"
            >
            </GMapAutocomplete>
            <input 
                class="text-small" 
                placeholder="Check zip code" 
                v-model="zipCode" 
                @input="checkZipCode">
            <span class="zip-validation-result">
                <span v-if="zipValidationResult === true">✅ {{ zipZone }}</span>
                <span v-else-if="zipValidationResult === false">❌ Not in coverage area</span>
            </span>
        </div>
    </div>
</template>

<script>
import { GMapAutocomplete } from '@fawmi/vue-google-maps';
import { mapState } from  '@/mapstore.js';

export default {
    props: {
        label: String,
    },
    components: {
        GMapAutocomplete,
    },

    data() {
        return {
            zipCode: '',
            zipValidationResult: null,
            mapState
        };
    },

    mounted() {
        if (Object.keys(mapState.markets).length === 0) {
            mapState.fetchMarkets();
        }
    },

    methods: {

        isValidCoordinate(coordinate) {
            return coordinate !== '' && !isNaN(parseFloat(coordinate)) && isFinite(coordinate);
        },

        updateAutocompleteValue(newValue) {
            const autocompleteInput = this.$refs.autocomplete?.$refs.input;
            if (autocompleteInput) {
                autocompleteInput.value = newValue;
            }
        },

        setPlace(place) {
            if (!place || !place.geometry) {
                console.error('No valid place data');
                return;
            }
            this.zipCode = '';
            let localStreet = '';
            let localZip = '';
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case 'street_number':
                        localStreet = `${component.long_name} ${localStreet}`;
                        break;
                    case 'route':
                        localStreet += component.short_name;
                        break;
                    case 'postal_code':
                        localZip = component.long_name;
                        break;
                }
            }
            let latitude, longitude;
            if (typeof place.geometry.location.lat === 'function') {
                latitude = place.geometry.location.lat();
            } else {
                latitude = place.geometry.location.lat;
            }
            if (typeof place.geometry.location.lng === 'function') {
                longitude = place.geometry.location.lng();
            } else {
                longitude = place.geometry.location.lng;
            }
            if (this.isValidCoordinate(latitude) && this.isValidCoordinate(longitude)) {
                mapState.markerLatLng = [latitude, longitude];
            } else {
                mapState.markerLatLng = [];
            }
            if (localZip !== '') {
                this.zipCode = localZip;
                this.checkZipCode();
            }
        },
        async checkZipCode() {
            if (Object.keys(mapState.markets).length === 0) {
                await mapState.fetchMarkets();
            }
            if (this.zipCode.length === 5 && !isNaN(this.zipCode)) {
                for (let marketCode in mapState.markets) {
                    const market = mapState.markets[marketCode];
                    for (let zone of market.zones) {
                        for (let zoneNumber in zone) {
                            if (zone[zoneNumber].includes(parseInt(this.zipCode))) {
                                this.zipZone = `${marketCode} Zone ${zoneNumber}`;
                                this.zipValidationResult = true;
                                mapState.centeredZone = [marketCode, zoneNumber];
                                return;
                            }
                        }
                    }
                }
                this.zipValidationResult = false;
            } else {
                this.zipValidationResult = null;
            }
        },
    }
};
</script>

<style scoped>
.form-field {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    margin-top: 10px;
}

.form-field-addr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.text-wide, .text-small {
    font-size: 16px;
    padding: 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.text-wide {
    flex-grow: 1;
    max-width: 600px;
}

.text-small {
    width: 300px;
}

.zip-validation-result {
    min-width: 200px;
    text-align: center;
}
</style>

