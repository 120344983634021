import { reactive } from 'vue';
import axios from 'axios';

export const mapState = reactive({
    markerLatLng: [],
    centeredZone: [],
    reset: false,
    markets: {},
    googleAddress: '',
    loadingMarkets: false, 
    async fetchMarkets() {
        this.loadingMarkets = true;
        try {
            const response = await axios.get('https://ars-geojson.s3.amazonaws.com/markets.json');
            this.markets = response.data;
            console.log('Market data loaded:', this.markets)
        } catch (error) {
            console.error('Failed to load markets data:', error);
            this.markets = {};
        } finally {
            this.loadingMarkets = false;
        }
    }
});
