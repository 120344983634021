<template>
  <div class="form-container">
    <h1>Retail additional services</h1>
    <p>Input an existing WO to send an invoice for an additional service to the customer.</p>
    <hr />

    <h3>
      WO Number
    </h3>
    <div class="form-row">
      <div class="form-field">
        <input type="text" v-model="formData.wo" placeholder="Enter text" />
        <button @click="fetchWO"
                :disabled="!validWo"
                :class="{'button-enabled': validWo, 'button-disabled': !validWo}"
                class="fetch-button">
          Fetch WO
        </button>
      </div>
    </div>

    <h3>
      Services
    </h3>
    <div class="form-row">
        <div class="checkbox-group">
            <label class="custom-checkbox">
                <input type="checkbox" value="Jump Start" v-model="formData.selectedServices" />
                <span class="checkbox-label">Jump Start</span>
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" value="Lock Out" v-model="formData.selectedServices" />
                <span class="checkbox-label">Lock Out</span>
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" value="Tire Change" v-model="formData.selectedServices" />
                <span class="checkbox-label">Tire Change</span>
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" value="Fuel Delivery" v-model="formData.selectedServices" />
                <span class="checkbox-label">Fuel Delivery</span>
            </label>
        </div>
      <div class="form-row">
        <div class="checkbox-group">
            <label class="custom-checkbox">
                <input type="checkbox" value="Fuel" v-model="formData.selectedServices" />
                <span class="checkbox-label">Fuel</span>
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" value="Highway" v-model="formData.selectedServices" />
                <span class="checkbox-label">Highway</span>
            </label>
        </div>
      </div>
    </div>
    
    <div class="form-row">
    <h3>
      Customer phone 
    </h3>
      <div class="form-field">
        <input type="tel" v-model="formData.phoneNum" placeholder="Phone number" pattern="\d*" />
      </div>
    </div>

    <div class="form-row">
    <h3>
      Provider
    </h3>
        <div class="checkbox-group">
          <label class="custom-radio">
            <input type="radio" name="provider" value="ARS" v-model="formData.provider">
            <span class="radio-label">ARS</span>
          </label>
          <label class="custom-radio">
            <input type="radio" name="provider" value="CurbsideSOS" v-model="formData.provider">
            <span class="radio-label">CSOS</span>
          </label>
        </div>
    </div>
    <br>

    <div class="button-container">
      <button @click="resetFormData" class="create-button" :class="'button-enabled'">
          Reset form
      </button>
      <button @click="submitForm"
              :disabled="!isFormValid || isSubmitted"
              :class="{'button-enabled': isFormValid && !isSubmitted && woExists, 'button-disabled': !isFormValid || isSubmitted}"
              class="create-button">
        Send invoice
      </button>
    </div>
    
    <div v-if="isFetched && woExists" class="response-message">
      ☑️ Located WO {{ fetchedWo }}
    </div>
    <div v-else-if="isFetched && !woExists" class="response-message">
      ❌ Error: {{ fetchError }}
    </div>
    <div v-if="isSubmitted" class="response-message">
      {{ formRes }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, computed } from 'vue';

export default {
    name: 'AddonComponent',
    setup() {
      const formData = ref({
        wo: '',
        selectedServices: [],
        phoneNum: '',
        provider: 'ARS',
      });
      const woExists = ref(false);
      const fetchedWo = ref('');
      const fetchError = ref('');
      const isFetched = ref(false);
      const isSubmitted = ref(false);
      const formRes = ref('');
      const svcList = ref(['Fuel', 'Jump Start', 'Tire Change', 'Lock Out', 'Fuel Delivery', 'Highway', 'Additional service']);

      const validWo = computed(() => {
        return formData.value.wo.length === 11 && /^\d+$/.test(formData.value.wo);
      });

      const isFormValid = computed(() => {
        const hasRequiredFields = formData.value.wo &&
          formData.value.phoneNum &&
          formData.value.selectedServices.length > 0;
        return hasRequiredFields && woExists.value && fetchedWo.value === formData.value.wo;
      });

      async function fetchWO() {
        this.isFetched = false;
        this.fetchError = '';
        const res = await axios.get(`/addon/wo?wo=${this.formData.wo}`);
        this.isFetched = true;
        this.fetchedWo = res.data.wo;
        this.woExists = res.data.valid;
        if (this.woExists) {
          this.formData.phoneNum = res.data.phone;
          if (res.data.provider === "CurbsideSOS") {
            this.formData.provider = res.data.provider;
          }
        } else {
          this.formData.wo = '';
          this.formData.phoneNum = '';
          this.fetchError = res.data.error;
        }
      }

      async function submitForm() {
        if (this.isFormValid && !this.isSubmitted) {
          this.isSubmitted = true;
          try {
            const res = await axios.post('/addon/invoice', this.formData);
            if (res.status === 200) {
              const result = res.data;
              if (result.valid) {
                this.formRes = `☑️ Invoice sent to ${result.phone}`;
              } else {
                this.formRes = result.error;
              }
            } else {
              this.formRes = '❌ An error occurred. Please try again.';
            }
          } catch (error) {
            if (error.response) {
              this.formRes = error.response.data.message || '❌ An error occurred. Please try again.';
            } else if (error.request) {
              this.formRes = '❌ No response from server. Please check your network connection.';
            } else {
              this.formRes = '❌ Error: ' + error.message;
            }
          }
        }
      }

      return {
        formData,
        isSubmitted,
        woExists,
        svcList,
        fetchedWo,
        fetchError,
        isFetched,
        validWo,
        isFormValid,
        formRes,
        fetchWO,
        submitForm,
      };
    },
    methods: {
      resetFormData() {
        this.formData.wo = '';
        this.formData.selectedServices = [];
        this.formData.phoneNum = '';
        this.woExists = false;
        this.fetchedWo = '';
        this.fetchError = '';
        this.isFetched = false;
        this.isSubmitted = false;
        this.formRes = '';
        this.formData.provider = 'ARS';
      }
    }
};
</script>

<style scoped>
.form-container {
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  max-width: 20%; 
  min-width: 600px;
  background-color: #FAF8F8;
  margin: 20px auto;
  padding: 60px;
  padding-top: 10px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  box-shadow: 0 2px 4px hsla(0, 0%, 62%, 0.714);
}

.form-container form {
  text-align: left;
}

.form-container label, 
.form-container input,
.form-container select {
  display: block;
  width: 80%;
  box-sizing: border-box;
}

.form-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.form-field {
  display: flex;
  flex-grow: 1; 
  flex-shrink: 1; 
  flex-basis: 30%;
  max-width: 100%;
  min-width: 150px;
}

input[type="text"], input[type="tel"], select, button {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.create-button, .fetch-button {
    justify-content: right;
}

.fetch-button {
  margin-left: 10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

button {
  color: white;
  cursor: pointer;
  border: none;
  padding: 15px;
  transition: background-color 0.3s;
  justify-content: right;
}

.button-enabled {
    background-color: #4682b4;
    padding: 15px;
    color: white;
}

.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
    padding: 15px;
    cursor: not-allowed;
}

.response-message {
    margin-top: 20px;
    color: #333;
    font-size: 22px;
    text-align: center;
    font-weight: bold;
}

.checkbox-group {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 5px; 
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.custom-checkbox span.checkbox-label {
  pointer-events: none;
  white-space: nowrap;
}

.custom-checkbox input[type="checkbox"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-checkbox .checkbox-label {
  display: inline;
}

.custom-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
}

.custom-radio input[type="radio"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.custom-radio span.radio-label {
  pointer-events: none;
  white-space: nowrap;
}

.custom-radio input[type="radio"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-radio .radio-label {
  display: inline;
}

</style>
