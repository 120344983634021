<template>
    <div>
    <hr>
    <h2>Invoice information</h2>
    <div class="response-message" v-if="scriptState.responseMessage !== ''">
        {{ scriptState.responseMessage }}
    </div>
    <div class="invoice-description">
        <table class="styled-table">
            <tbody>
                <tr v-if="scriptState.formData.motoristFirstName !== '' || scriptState.formData.motoristLastName !== ''">
                    <td class="table-title">Customer</td>
                    <td v-if="scriptState.formData.motoristFirstName">{{ scriptState.formData.motoristFirstName }} {{ scriptState.formData.motoristLastName }}</td>
                </tr>
                <tr v-if="scriptState.formData.googleAddress !== ''">
                    <td class="table-title">Location</td>
                    <td v-if="scriptState.formData.googleAddress !== ''">{{ scriptState.formData.googleAddress }}</td>
                </tr>
                <tr v-if="scriptState.formData.motoristCell">
                    <td class="table-title">Contact</td>
                    <td v-if="scriptState.formData.motoristCell !== ''">{{ scriptState.formData.motoristCell }} {{ scriptState.formData.motoristEmail }}</td>
                </tr>
                <tr v-if="scriptState.formData.vehicleMake !== '' || scriptState.formData.vehicleColor !== ''">
                    <td class="table-title">Vehicle</td>
                    <td v-if="scriptState.formData.vehicleMake">{{ scriptState.formData.vehicleColor }} {{ scriptState.formData.vehicleYear }} {{ scriptState.formData.vehicleMake }} {{ scriptState.formData.vehicleModel }}</td>
                </tr>
                <tr v-if="scriptState.formData.selectedServices.length > 0">
                    <td class="table-title">Services</td>
                    <td v-if="scriptState.formData.selectedServices.length > 0">{{ scriptState.formData.selectedServices.join(', ') }}</td>
                </tr>
                <tr v-if="scriptState.formData.comment !== ''">
                    <td class="table-title">Comments</td>
                    <td v-if="scriptState.formData.comment !== ''">{{ scriptState.formData.comment }}</td>
                </tr>
                <tr v-if="scriptState.scheduledTime !== ''">
                <td class="table-title">Time</td>
                <td>{{ formatDateTime(scriptState.scheduledTime) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
</template>

<script>
import { scriptState } from '@/store.js';
export default {
    setup() {
        return {
            scriptState
        };
    },
    methods: {
        formatDateTime(isoString) {
            const date = new Date(isoString);
            return date.toLocaleString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
        }
    }
}
</script>

<style scoped>
.response-message {
    margin-top: 20px;
    color: #333;
    font-size: 18px;
    text-align: center;
}

.invoice-description {
    margin-top: 20px;
    margin: 30px;
    color: #333;
    font-size: 18px;
    text-align: left;
    line-height: 1.8em;
}

.styled-table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 25px 0;
    font-size: 0.9em;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table tbody tr:first-child {
    border-top-left-radius: 5px;
}

.styled-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
}

.styled-table th, .styled-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #99b3c9;
}

.table-title {
    background-color: #4682b4;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
}
</style>