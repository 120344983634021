<template>
    <div class="side-container">
        <div class="form-field">

            <div class="form-row" v-if="publicState.formData.highwayCall === 'yes'">
                <div class="dialogue-label">Which side of your vehicle is traffic on?</div>
                <div class="side-radio-container">
                    <div class="radio-group">
                        <label class="custom-radio">
                            <input type="radio" name="highwaySide" value="Traffic on left side of vehicle" @change="event => updateSelectedSide(event, 'highway')" v-model="publicState.hwySide" />
                            <span class="radio-label">Left</span>
                        </label>
                        <label class="custom-radio">
                            <input type="radio" name="highwaySide" value="Traffic on right side of vehicle" @change="event => updateSelectedSide(event, 'highway')" v-model="publicState.hwySide" />
                            <span class="radio-label">Right</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row" v-if="showTireChange">
                <div class="dialogue-label">Flat on the left or the right side of vehicle?</div>
                <div class="side-radio-container">
                    <div class="radio-group">
                        <label class="custom-radio">
                            <input type="radio" name="tireSide" value="Tire on left side of vehicle" @change="event => updateSelectedSide(event, 'tireChange')" />
                            <span class="radio-label">Left</span>
                        </label>
                        <label class="custom-radio">
                            <input type="radio" name="tireSide" value="Tire on right side of vehicle" @change="event => updateSelectedSide(event, 'tireChange')" />
                            <span class="radio-label">Right</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-row" v-if="showFuelDelivery">
                <div class="dialogue-label">Gas cap on the left or the right side of vehicle?</div>
                <div class="side-radio-container">
                    <div class="radio-group">
                        <label class="custom-radio">
                            <input type="radio" name="fuelSide" value="Gas cap on left side of vehicle" @change="event => updateSelectedSide(event, 'fuelDelivery')" />
                            <span class="radio-label">Left</span>
                        </label>
                        <label class="custom-radio">
                            <input type="radio" name="fuelSide" value="Gas cap on right side of vehicle" @change="event => updateSelectedSide(event, 'fuelDelivery')" />
                            <span class="radio-label">Right</span>
                        </label>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { publicState } from '@/pubstore.js';

export default {  
    setup() {
        return { publicState };
    },
    computed: {
        areFieldsValid() {
            let isValid = true;
            if (this.showFuelDelivery) {
                const fuelDeliverySelected = publicState.selectedSide.some(item => item.group === 'fuelDelivery');
                isValid = isValid && fuelDeliverySelected;
            }
            if (this.showTireChange) {
                const tireChangeSelected = publicState.selectedSide.some(item => item.group === 'tireChange');
                isValid = isValid && tireChangeSelected;
            }
            if (publicState.formData.highwayCall === 'yes') {
                const highwaySideSelected = publicState.hwySide.length > 0;
                isValid = isValid && highwaySideSelected;
            }
            return isValid;
        },
        showFuelDelivery() {
            return publicState.formData.selectedServices.includes('Fuel Delivery');
        },
        showTireChange() {
            return publicState.formData.selectedServices.includes('Tire Change');
        }
    },
    methods: {
        updateSelectedSide(event, group) {
            if (group !== 'highway') {
                const value = event.target.value;
                const groupIndex = publicState.selectedSide.findIndex(item => item.group === group);
                if (groupIndex > -1) {
                    publicState.selectedSide[groupIndex].value = value;
                } else {
                    publicState.selectedSide.push({ group, value });
                }
            }
            this.updateFieldValidity();
        },
        updateFieldValidity() {
            publicState.fieldsValid = this.areFieldsValid;
        }
    }
};
</script>

<style scoped>
.side-container { 
  flex: 1;
  background-color: rgba(255, 255, 255, 0.9);
}

.side-radio-container{
    font-family: Nunito,sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: hsla(0,0%,100%,.9);
    box-shadow: 0 4px 8px rgba(0,0,0,.2);
    border: 1px solid #ddd;
}

.dialogue-label {
  font-size: 1.1em;
  margin-bottom: 20px;
}

body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.radio-group {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-evenly;
}

.custom-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 40px;
  width: 40px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-radio input[type="radio"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.custom-radio span.radio-label {
  pointer-events: none;
}

.custom-radio input[type="radio"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-radio .radio-label {
  display: inline;
}

@media only screen and (max-width: 768px) {
    .side-radio-container {
        font-family: 'Nunito', sans-serif;
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 20px;
        margin-bottom: 20px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border: 1px solid #ddd;
    }
}

</style>
