<script>
import { onMounted } from 'vue';
import { publicState } from  '@/pubstore.js';
import axios from 'axios';
export default {
    setup() {
        const getPrice = async () => {
            const payload = {
                make: publicState.formData.vehicleMake,
                model: publicState.formData.vehicleModel,
                year: publicState.formData.vehicleYear,
                color: publicState.formData.vehicleColor,
                services: publicState.formData.selectedServices,
                street: publicState.formData.street,
                city: publicState.formData.city,
                state: publicState.formData.state,
                zip: publicState.formData.zip,
                market: publicState.formData.market,
                motoristFirst: publicState.formData.motoristFirstName,
                motoristLast: publicState.formData.motoristLastName,
                motoristCell: publicState.formData.motoristCell,
                motoristEmail: publicState.formData.motoristEmail,
                highway: publicState.formData.highwayCall,
                googleAddress: publicState.formData.googleAddress,
                latitude: publicState.formData.latitude,
                longitude: publicState.formData.longitude,
                comment: publicState.formData.comment,
                provider: publicState.formData.provider,
            };
            try {
                const response = await axios.post('/public/price', payload);
                console.log("Response received:", response.data);
                publicState.invoicePrice = response.data.amount;
                publicState.validPrice = response.data.valid;
            } catch (error) {
                console.error("Error in price query:", error);
            }
        };
        onMounted(() => {
            getPrice();
        });
        return {
            publicState
        };
    }
}
</script>