<template>
  <div class="dialogue-container">
    <h2 class="titleText">{{ titleText }}</h2>
    <div class="dialogue">{{ dialogue }}</div>
  </div>
</template>

<script>
export default {
  props: {
    titleText: String,
    dialogue: String 
  }
};
</script>

<style scoped>

.dialogue-container {
  width: 100%;
  padding-right: 2.5%;
  padding-bottom:10px;
}

.titleText {
  font-size: 2em;
  font-weight: 800;
  text-align: left;
  font-family: 'Prompt', sans-serif;
  display: flow;
  margin-top: 0;
  margin-bottom: 5px;
}

.dialogue {
  font-size: 1.1em;
}

@media (max-width: 768px) {
  .dialogue-container {
    width: 100%;
    padding-right: 2.5%;
  }

  .titleText {
    font-size: 2.2em;
    font-weight: 800;
    text-align: left;
    font-family: 'Prompt', sans-serif;
    display: flow;
    margin-top: 0;
    margin-bottom: 2.5px;
  }

  .dialogue {
    font-size: 1.3em;
  }
}

@media (min-width: 1600px) {
  /* Apply styles for screens larger than 2000px */
  .dialogue-container {
    width: 100%;
    padding-right: 5%;
  }

  .titleText {
    font-size: 2.5em;
    /* Add any other styles you want for larger screens */
  }

  .dialogue {
    font-size: 1.5em;
    /* Add any other styles you want for larger screens */
  }
}

@media (min-width: 2000px) {
  /* Apply styles for screens larger than 2000px */
  .dialogue-container {
    width: 100%;
    padding-right: 5%;
  }

  .titleText {
    font-size: 4em;
    /* Add any other styles you want for larger screens */
  }

  .dialogue {
    font-size: 2.2em;
    /* Add any other styles you want for larger screens */
  }
}

</style>
