<template>
    <div class="form-field">
        <div class="form-row">
            <div class="radio-group">
                <label class="custom-radio">
                    <input type="radio" name="driveType" value="Front wheel drive" @change="event => updateTowNotes(event, 'driveType')" />
                    <span class="radio-label">Front wheel</span>
                </label>
                <label class="custom-radio">
                    <input type="radio" name="driveType" value="Rear wheel drive" @change="event => updateTowNotes(event, 'driveType')" />
                    <span class="radio-label">Rear wheel</span>
                </label>
                <label class="custom-radio">
                    <input type="radio" name="driveType" value="Four wheel drive" @change="event => updateTowNotes(event, 'driveType')" />
                    <span class="radio-label">Four wheel</span>
                </label>
            </div>
            Which type of drive?
        </div>
    </div>
</template>

<script>
import { scriptState } from '@/store.js';

export default {
    setup() {
        return { scriptState };
    },
    methods: {
        updateTowNotes(event, group) {
            const value = event.target.value;
            const groupIndex = scriptState.selectedSide.findIndex(item => item.group === group);
            if (groupIndex > -1) {
                scriptState.selectedSide[groupIndex].value = value;
            } else {
                scriptState.selectedSide.push({ group, value });
            }
            scriptState.fieldsValid = true;
        }
    }
};
</script>

<style scoped>
.radio-group {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.custom-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-radio input[type="radio"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.custom-radio span.radio-label {
  pointer-events: none;
}

.custom-radio input[type="radio"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-radio .radio-label {
  display: inline;
}
</style>
