<template>
    <div class="form-field">
        <span v-if="isResValidating">⏳ Validating vehicle...</span>
        <span v-else-if="isResValid === true && vehicleModel !== '' && vehicleYear !== '' && selectedServices.length > 0">✅ Help is available</span>
        <span v-else-if="isResValid === false">❌ Unable to assist with this vehicle</span>
    </div>
    <div class="form-field">
        <div class="vehicleColorTitleText">Color</div>
        <input type="text" id="vehicleColor" v-model="vehicleColor" class="color-field">
    </div>
    <div class="form-field">
        <label for="vehicleMake">Make </label>
        <select id="vehicleMake" v-model="vehicleMake" class="dropdown">
            <option v-for="make in vehicleData.filter(make => make.label !== 'Other')" :key="make.value" :value="make.value">{{ make.label }}</option>
        </select>
    </div>
    <div v-if="selectedMake" class="form-field">
        <label for="vehicleModel">Model </label>
        <select id="vehicleModel" v-model="vehicleModel" class="dropdown">
            <option v-for="model in selectedMake.children" :key="model.value" :value="model.value">{{ model.label }}</option>
        </select>
    </div>
    <div v-if="selectedModel" class="form-field">
        <label for="vehicleYear">Year </label>
        <select id="vehicleYear" v-model="vehicleYear" class="dropdown">
            <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
    </div>
</template>

<script>
import { computed } from 'vue';
import { scriptState } from  '@/store.js';
import axios from 'axios';

export default {
    props: {
        label: String,
    },
    setup() {
        const vehicleData = computed({
            get: () => scriptState.vehicleData,
            set: value => { scriptState.vehicleData = value; }
        });
        const vehicleColor = computed({
            get: () => scriptState.formData.vehicleColor,
            set: value => { scriptState.formData.vehicleColor = value; }
        });
        const vehicleMake = computed({
            get: () => scriptState.formData.vehicleMake,
            set: value => { scriptState.formData.vehicleMake = value; }
        });
        const vehicleModel = computed({
            get: () => scriptState.formData.vehicleModel,
            set: value => { scriptState.formData.vehicleModel = value; }
        });
        const vehicleYear = computed({
            get: () => scriptState.formData.vehicleYear,
            set: value => { scriptState.formData.vehicleYear = value; }
        });
        const isResValid = computed({
            get: () => scriptState.isResValid,
            set: value => { scriptState.isResValid = value; }
        });
        const isResValidating = computed({
            get: () => scriptState.isResValidating,
            set: value => { scriptState.isResValidating = value; }
        });
        const selectedServices = computed({
            get: () => scriptState.formData.selectedServices,
            set: value => { scriptState.formData.selectedServices = value; }
        });
        return {
            vehicleData,
            vehicleColor,
            vehicleMake,
            vehicleModel,
            vehicleYear,
            isResValid,
            isResValidating,
            selectedServices
        };
    },
    mounted() {
        this.fetchVehicleData();
    },
    computed: {
        selectedMake() {
            return scriptState.vehicleData.find(make => make.value === scriptState.formData.vehicleMake);
        },
        selectedModel() {
            return this.selectedMake ? this.selectedMake.children.find(model => model.value === scriptState.formData.vehicleModel) : null;
        }, 
        isFormDataComplete() {
            return scriptState.formData.vehicleYear && scriptState.formData.vehicleMake && scriptState.formData.vehicleModel && scriptState.formData.selectedServices.length > 0;
        },
        vehicleYearCompute() {
            return scriptState.formData.vehicleYear;
        },
        vehicleMakeCompute() {
            return scriptState.formData.vehicleMake;
        },
        vehicleModelCompute() {
            return scriptState.formData.vehicleModel;
        },
        selectedServicesCompute() {
            return scriptState.selectedServices;
        },
        years() {
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let year = currentYear + 1; year >= 1970; year--) {
                years.push(`${year}`);
            }
            return years;
        },
    },
    methods: {
        async fetchVehicleData() {
            try {
                const response = await axios.get('/veh');
                if (response.data.code === "000000") {
                    scriptState.vehicleData = response.data.data;
                } else {
                    console.error('Invalid response fetching the vehicle data');
                }
            } catch (error) {
                console.error('There was an error fetching the vehicle data:', error);
            }
        },
        async validateRestrictions() {
            if (this.isFormDataComplete) {
                scriptState.isResValidating = true;
                const payload = {
                    make: scriptState.formData.vehicleMake.trim(),
                    model: scriptState.formData.vehicleModel.trim(),
                    year: scriptState.formData.vehicleYear.trim(),
                    services: scriptState.formData.selectedServices,
                };
                try {
                    const response = await axios.post('/restriction', payload);
                    if (response.data.make === payload.make &&
                        response.data.model === payload.model &&
                        response.data.year === payload.year &&
                        this.arraysEqual(response.data.services, payload.services)) {
                            scriptState.isResValid = response.data.valid;
                            scriptState.fieldsValid = response.data.valid;
                        }
                } catch (error) {
                    console.error('Error validating restrictions:', error);
                } finally {
                    scriptState.isResValidating = false;
                }
            }
        },
        arraysEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;
        }
    },
    watch: {
        vehicleYearCompute() {
            this.validateRestrictions();
        },
        vehicleMakeCompute() {
            this.validateRestrictions();
        },
        vehicleModelCompute() {
            this.validateRestrictions();
        },
        selectedServicesCompute() {
            this.validateRestrictions();
        }
    },
};
</script>

<style scoped>
.form-field {
    flex-grow: 1; 
    flex-shrink: 1; 
    flex-basis: 30%;
    max-width: 50%;
    min-width: 180px;
    align-items: center;
}

.form-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dropdown {
    max-width: 100%;
    position: relative;
    font-size: 16px;
    padding: 10px;
}

.color-field {
    max-width: 100%;
    position: relative;
    font-size: 16px;
    padding: 10px;
}

.vehicleColorTitleText {
    margin-bottom: 5px;
}

</style>