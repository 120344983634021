<template>
    <div class="form-field">
        <span v-if="isResValidating">⏳ Validating vehicle...</span>
        <span v-else-if="isResValid === true && vehicleModel !== '' && vehicleYear !== '' && selectedServices.length > 0">✅ Help is available</span>
        <span v-else-if="isResValid === false">❌ Unable to assist with this vehicle</span>
    </div>
    <div class="form-field">
        <div class="vehicleColorTitleText">Color</div>
        <input type="text" id="vehicleColor" v-model="vehicleColor" class="color-field">
    </div>
    <div class="form-field">
        <label for="vehicleMake">Make </label>
        <select id="vehicleMake" v-model="vehicleMake" class="dropdown">
            <option v-for="make in vehicleData.filter(make => make.label !== 'Other')" :key="make.value" :value="make.value">{{ make.label }}</option>
        </select>
    </div>
    <div v-if="selectedMake" class="form-field">
        <label for="vehicleModel">Model </label>
        <select id="vehicleModel" v-model="vehicleModel" class="dropdown">
            <option v-for="model in selectedMake.children" :key="model.value" :value="model.value">{{ model.label }}</option>
        </select>
    </div>
    <div v-if="selectedModel" class="form-field">
        <label for="vehicleYear">Year </label>
        <select id="vehicleYear" v-model="vehicleYear" class="dropdown">
            <option v-for="year in selectedModel.children" :key="year.value" :value="year.value">{{ year.label }}</option>
        </select>
    </div>
</template>

<script>
import { computed } from 'vue';
import { scriptState } from  '@/store.js';
import axios from 'axios';

export default {
    props: {
        label: String,
    },
    setup() {
        const vehicleData = computed({
            get: () => scriptState.vehicleData,
            set: value => { scriptState.vehicleData = value; }
        });
        const vehicleColor = computed({
            get: () => scriptState.formData.vehicleColor,
            set: value => { scriptState.formData.vehicleColor = value; }
        });
        const vehicleMake = computed({
            get: () => scriptState.formData.vehicleMake,
            set: value => { scriptState.formData.vehicleMake = value; }
        });
        const vehicleModel = computed({
            get: () => scriptState.formData.vehicleModel,
            set: value => { scriptState.formData.vehicleModel = value; }
        });
        const vehicleYear = computed({
            get: () => scriptState.formData.vehicleYear,
            set: value => { scriptState.formData.vehicleYear = value; }
        });
        return {
            vehicleData,
            vehicleColor,
            vehicleMake,
            vehicleModel,
            vehicleYear,
        };
    },
    mounted() {
        this.fetchVehicleData();
    },
    computed: {
        selectedMake() {
            return scriptState.vehicleData.find(make => make.value === scriptState.formData.vehicleMake);
        },
        selectedModel() {
            return this.selectedMake ? this.selectedMake.children.find(model => model.value === scriptState.formData.vehicleModel) : null;
        }, 
        isFormDataComplete() {
            return scriptState.formData.vehicleYear && scriptState.formData.vehicleMake && scriptState.formData.vehicleModel;
        },
        vehicleYearCompute() {
            return scriptState.formData.vehicleYear;
        },
        vehicleMakeCompute() {
            return scriptState.formData.vehicleMake;
        },
        vehicleModelCompute() {
            return scriptState.formData.vehicleModel;
        }
    },
    methods: {
        async fetchVehicleData() {
            try {
                const response = await axios.get('/veh');
                if (response.data.code === "000000") {
                    scriptState.vehicleData = response.data.data;
                } else {
                    console.error('Invalid response fetching the vehicle data');
                }
            } catch (error) {
                console.error('There was an error fetching the vehicle data:', error);
            }
        },
        async validateVehicle() {
            if (this.isFormDataComplete) {
                scriptState.fieldsValid = true;
            }
        },
    },
    watch: {
        vehicleYearCompute() {
            this.validateVehicle();
        },
        vehicleMakeCompute() {
            this.validateVehicle();
        },
        vehicleModelCompute() {
            this.validateVehicle();
        },
        selectedServicesCompute() {
            this.validateVehicle();
        }
    },
};
</script>

<style scoped>
.form-field {
    flex-grow: 1; 
    flex-shrink: 1; 
    flex-basis: 30%;
    max-width: 50%;
    min-width: 180px;
    align-items: center;
}

.form-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dropdown {
    max-width: 100%;
    min-width: 400px;
    position: relative;
    font-size: 16px;
    padding: 10px;
}

.color-field {
    max-width: 100%;
    min-width: 400px;
    position: relative;
    font-size: 16px;
    padding: 10px;
}

.vehicleColorTitleText {
    margin-bottom: 5px;
}

</style>