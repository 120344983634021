<template>
    <div class="popover-backdrop">
        <div class="popover-content" @click.stop>            
                <div class="titleText-container">
                <span class="popup-titleText">
                    Where are you currently located?
                </span>
                </div>
                <div class="dialogue-container">
                <span class="popup-dialogue">
                    Input your location to see services offered in your area:
                </span>
                </div>
            <div class="map-container">
                <Map
                    :center="markerPosition"
                    :zoom="10"
                    map-type-id="roadmap"
                    class="map"
                >
                    <Marker
                        :position="markerPosition"
                        :draggable="true"
                        @dragend="onMarkerDragEnd"
                    ></Marker>
                </Map>
            </div>
            <div class="form-field">
                <GMapAutocomplete
                    placeholder="Enter vehicle location"
                    v-model="publicState.formData.googleAddress"
                    ref="autocomplete"
                    @place_changed="setPlace"
                    type="text"
                    class="text-wide"
                ></GMapAutocomplete>
            </div>
            <div class="response-message">
                <span v-if="publicState.isZipValidating">Checking your area...</span>
                <!-- <span v-else-if="publicState.zipValidationResult === false">Unfortunately, we don't have any Technicians in your area and can't assist.</span> -->
                <span v-else-if="publicState.formData.city !== '' && publicState.formData.zip === ''">Please input an address with a zip code to continue</span>
            </div>
            <div v-if="etaLoaded" class="eta-message">
                <span>{{ avgEta }} minute estimated wait</span>
            </div>
            <div class="continue" v-if="publicState.zipValidationResult && etaLoaded">
                <button
                    @click="closePopover()"
                >Continue</button>
            </div>
            <div class="divider-container" v-if="publicState.zipValidationResult !== false && !locationRequested">
                <span class="divider-or">Or</span>
            </div>
            <div class="continue">
                <button
                    v-if="!locationRequested" 
                    @click="getLocation"
                >Detect my location</button>
            </div>
            <div v-if="locErrorMessage" class="error">{{ locErrorMessage }}</div>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { Map, Marker, GMapAutocomplete } from '@fawmi/vue-google-maps';
import axios from 'axios';
import { publicState } from  '@/pubstore.js';

export default {
    props: {
        label: String,
    },

    components: {
        Map,
        Marker,
        GMapAutocomplete,
    },

    setup() {
        const avgEta = ref(0);
        const etaLoaded = ref(false);
        return {
            publicState,
            isZipValidating: computed(() => publicState.isZipValidating),
            zipValidationResult: computed(() => publicState.zipValidationResult),
            avgEta,
            etaLoaded
        };
    },
    
    data() {
        return {
            defaultLocation: {
                lat: 32.7763, 
                lng: -96.7934
            },
            locationRequested: false,
            locErrorMessage: '',
        };
    },

    mounted() {
        this.fetchLocation();
    },

    computed: {
        markerPosition() {
            const lat = publicState.formData.latitude;
            const lng = publicState.formData.longitude;
            if (this.isValidCoordinate(lat) && this.isValidCoordinate(lng)) {
                return {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                };
            }
            return this.defaultLocation;
        }
    },
    methods: {
        isValidCoordinate(coordinate) {
            return coordinate !== '' && !isNaN(parseFloat(coordinate)) && isFinite(coordinate);
        },

        updateAutocompleteValue(newValue) {
            const autocompleteInput = this.$refs.autocomplete?.$refs.input;
            if (autocompleteInput) {
                autocompleteInput.value = newValue;
            }
        },

        setPlace(place) {
            if (!place || !place.geometry) {
                console.error('No valid place data');
                return;
            }
            publicState.formData.street = '';
            publicState.formData.city = '';
            publicState.formData.state = '';
            publicState.formData.zip = '';
            publicState.formData.googleAddress = '';
            publicState.formData.latitude = '';
            publicState.formData.longitude = '';
            let localStreet = '';
            let localCity = '';
            let localState = '';
            let localZip = '';
            for (const component of place.address_components) {
                component.types.forEach((componentType) => {
                    switch (componentType) {
                        case 'street_number':
                            localStreet = `${component.long_name} ${localStreet}`.trim();
                            break;
                        case 'route':
                            localStreet += ` ${component.short_name}`.trim();
                            break;
                        case 'locality':
                            localCity = component.long_name;
                            break;
                        case 'administrative_area_level_1':
                            localState = component.short_name;
                            break;
                        case 'postal_code':
                            localZip = component.long_name;
                            break;
                    }
                });
            }
            publicState.formData.street = localStreet;
            publicState.formData.city = localCity;
            publicState.formData.state = localState;
            publicState.formData.zip = localZip;
            publicState.formData.googleAddress = place.formatted_address;
            this.$nextTick(() => {
                this.updateAutocompleteValue(publicState.formData.googleAddress);
            });
            let latitude, longitude;
            if (typeof place.geometry.location.lat === 'function') {
                latitude = place.geometry.location.lat();
            } else {
                latitude = place.geometry.location.lat;
            }
            if (typeof place.geometry.location.lng === 'function') {
                longitude = place.geometry.location.lng();
            } else {
                longitude = place.geometry.location.lng;
            }
            if (this.isValidCoordinate(latitude) && this.isValidCoordinate(longitude)) {
                publicState.formData.latitude = latitude;
                publicState.formData.longitude = longitude;
            }
        },

        onMarkerDragEnd(event) {
            const newLat = event.latLng.lat();
            const newLng = event.latLng.lng();
            this.reverseGeocode(newLat, newLng);
        },

        async reverseGeocode(latitude, longitude) {
            try {
                const response = await fetch('/public/geocode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ latitude, longitude }),
                });
                if (!response.ok) {
                    throw new Error('Reverse geocoding failed');
                }
                const place = await response.json();
                const routeIndex = place.results.findIndex(result => 
                    result.types && (result.types.includes('street_address') || result.types.includes('route'))
                );
                if (routeIndex !== -1) {
                    this.setPlace(place.results[routeIndex]);
                } else {
                    console.error('Invalid reverse geocoding');
                    this.validateZip('');
                }
            } catch (error) {
                console.error('Error in reverse geocoding:', error);
            }
        },

        async validateZip(zipCode) {
            publicState.isZipValidating = true;
            publicState.zipValidationResult = null;
            try {
                const response = await axios.get(`/public/zip?zip=${zipCode}`);
                publicState.zipValidationResult = response.data.valid;
                publicState.fieldsValid = response.data.valid;
                publicState.formData.market = response.data.market;
                publicState.formData.provider = response.data.provider;
                if (!response.data.valid) {
                    this.locErrorMessage = "Unfortunately, we don't have any Technicians in your area and can't assist.";
                } else {
                    this.locErrorMessage = "";
                }
            } catch (error) {
                console.error('Error validating zip code:', error);
            }
            publicState.isZipValidating = false;
            try {
                const payload = { market: publicState.formData.market };
                const responseEta = await axios.post('/public/eta', payload);
                if (responseEta.data.valid) {
                    this.avgEta = responseEta.data.eta;
                    this.locErrorMessage = "";
                } else {
                    this.avgEta = responseEta.data.eta;
                    this.locErrorMessage = "Unfortunately, we don't have any Technicians in your area and can't assist.";
                }
                this.etaLoaded = responseEta.data.valid;
            } catch (error) {
                console.error("Error loading market data:", error);
                this.etaLoaded = false;
                this.locErrorMessage = "Unfortunately, we don't have any Technicians in your area and can't assist.";
            }
        },
        
        closePopover() {
            this.$emit('close');
        },

        fetchLocation() {
            axios.post('/public/loc', {})
                .then(response => {
                    if (response.data.valid && response.data.lat !== 0 && response.data.lon !== 0) {
                        this.defaultLocation = {
                            lat: response.data.lat,
                            lng: response.data.lon
                        };
                    }
                })
                .catch(error => {
                    console.error('Error fetching location:', error);
                });
        },

        getLocation() {
            if (navigator.geolocation) {
                this.locationRequested = true;
                navigator.geolocation.getCurrentPosition(this.positionSuccess, this.positionError);
            } else {
                this.locErrorMessage = 'Geolocation is not supported by this browser.';
            }
        },

        positionSuccess(position) {
            this.defaultLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };
            this.reverseGeocode(position.coords.latitude,position.coords.longitude);
        },

        positionError(error) {
            switch(error.code) {
                case error.PERMISSION_DENIED:
                this.locErrorMessage = "Browser denied the request for geolocation. Please make sure your browser allows location sharing.";
                break;
                case error.POSITION_UNAVAILABLE:
                this.locErrorMessage = "Location information is unavailable.";
                break;
                case error.TIMEOUT:
                this.locErrorMessage = "The request to get user location timed out.";
                break;
                case error.UNKNOWN_ERROR:
                this.locErrorMessage = "An unknown error occurred.";
                break;
            }
            this.locationRequested = false;
        },
    },

    watch: {
        'publicState.formData.zip': function(newZip) {
            if (newZip.length === 5 && /^\d+$/.test(newZip)) {
                this.validateZip(newZip);
            } else {
                publicState.zipValidationResult = null;
            }
        },
    },
};
</script>

<style scoped>
.title-text {
    font-size: 2em;
    font-weight: 800; 
    text-align: left; 
    font-family: 'Prompt', sans-serif;
    display: flow;
    margin-top:0px;
    margin-bottom: 5px;
}

.titleText-container{
    padding-bottom:10px;
}

.divider-container {
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.divider-container:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px; 
    background-color: #ccc;
    z-index: 1;
}

.divider-or {
    position: relative;
    background-color: #fff; 
    color: #000; 
    padding: 0 10px; 
    z-index: 2;
}

.popup-titleText {
    font-size: 2em;
  font-weight: 800;
  line-height: 1.3em;
  text-align: left;
  font-family: 'Prompt', sans-serif;
  margin-bottom: 10px;
}

.popup-dialogue {
  text-align: left;
  font-size: 1.1em;

}



.subhead-text {
    font-size: 1.1em;
    text-align: left;
    display: flow;
    margin-bottom: 10px;
    line-height: 30px;
}

.response-message {
    font-size: 1.1em;
    display: flow;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-bottom:10px;
}

.eta-message {
    font-size: 1.1em;
    display: flow;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-bottom:10px;
    line-height: 21px;
}

.popover-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: left;
    z-index: 999; 
}

.popover-content {
    text-align: left;
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: flex-start; 
    padding: 20px;
    align-items: center;
    font-weight: 300;
    display: block;
    position: relative;
    width: 100%;
    min-height: 100%; 
    box-sizing: border-box
}





.form-field {
    display: flex;
    align-items: center;
    max-width: 100%;
    min-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.form-field-addr {
    width: 100%;
    margin: 20px;
}

.text-wide {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.text-wide ::placeholder {
    color: white;
}

.continue {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: center;
    margin-top: 5px;
    width: 10%;
}

.continue button {
    color: #fff;
    text-align: center;
    background-color: #48a8dc;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6em;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
    box-shadow: 0 20px 30px -10px rgba(24, 16, 99, .36);
    width: 100%;
    z-index: 1000;
}

.continue button:hover {
    background-color: #5e5e5e;
    transform: translateY(-1px);
}




.map {
        height: 250px;
        width: 100%;
        margin-bottom: 15px;
}


.form-field {
display: flex;
align-items: center;
max-width: 100%;
min-width: 100%;
}

.form-field-addr {
width: 100%;
margin: 20px;
}

.text-wide {
display: flex;
width: 100%;
font-size: 16px;
justify-content: center;
align-items: center;
padding: 10px;
gap: 10px;
}

.continue {
display: flex;
flex-direction: column;
justify-content: stretch;
align-items: center;
margin-top: 5px;
width: 100%;
z-index: 1000;
}

.continue button {
color: #fff;
text-align: center;
background-color: #48a8dc;
border-radius: 5px;
padding: 15px;
font-size: 16px;
font-weight: 500;
line-height: 1.6em;
text-decoration: none;
transition: background-color 0.3s, transform 0.2s;
cursor: pointer;
box-shadow: 0 20px 30px -10px rgba(24, 16, 99, .36);
width: 100%;
z-index: 1000;
}

.continue button:hover {
background-color: #5e5e5e;
transform: translateY(-1px);
}

.ars-header {
  height: 45px;
  object-fit: contain;
  display: block;
  margin: 0 auto; 
}

@media (min-width: 1200px) {
    .continue button {
        font-size: 1rem;
    }
}

@media (min-width: 769px) { /* Targets screens wider than 768px, which are typically tablets and desktops */
  .map-container {
    display: none; /* Hides the map container on devices larger than mobile */
  }

  .dialogue-container{
    padding-bottom:10px;
    }
}

@media (max-width: 769px) { /* Targets screens wider than 768px, which are typically tablets and desktops */
  .map-container {
    margin-top:10px;
  }
  .popover-content {
        padding-bottom: 60px; /* Add extra padding at the bottom for mobile devices */
    }
    .continue {
        width: 100%; /* Ensure the button container covers full width on mobile */
    }
    .continue button {
        margin: 0 20px; /* Add some margin to ensure it doesn't touch the screen edges */
    }
}


@media (max-height: 400px) {
    .title-text {
    font-size: 2em;
}
.map {
        height: 200px;
}


}
</style>