<template>
    <div class="invoice-container">
        <div v-if="publicState.responseMessage !== ''" class="response-message">
            {{ publicState.responseMessage }}
        </div>
        <table class="styled-table">
            <tbody>
                <!-- Name -->
                <template v-if="publicState.formData.motoristFirstName !== '' || publicState.formData.motoristLastName !== ''">
                    <tr>
                        <td class="table-title" colspan="2">Name</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ publicState.formData.motoristFirstName }} {{ publicState.formData.motoristLastName }}</td>
                    </tr>
                </template>
                
                <!-- Location -->
                <template v-if="publicState.formData.googleAddress !== ''">
                    <tr>
                        <td class="table-title" colspan="2">Location</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ publicState.formData.googleAddress }}</td>
                    </tr>
                </template>
                
                <!-- Cell -->
                <template v-if="publicState.formData.motoristCell">
                    <tr>
                        <td class="table-title" colspan="2">Cell</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ publicState.formData.motoristCell }}</td>
                    </tr>
                </template>
                
                <!-- Email -->
                <template v-if="publicState.formData.motoristEmail">
                    <tr>
                        <td class="table-title" colspan="2">Email</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ publicState.formData.motoristEmail }}</td>
                    </tr>
                </template>
                
                <!-- Vehicle -->
                <template v-if="publicState.formData.vehicleMake !== ''">
                    <tr>
                        <td class="table-title" colspan="2">Vehicle</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ publicState.formData.vehicleColor }} {{ publicState.formData.vehicleYear }} {{ publicState.formData.vehicleMake }} {{ publicState.formData.vehicleModel }}</td>
                    </tr>
                </template>
                
                <!-- Services -->
                <template v-if="publicState.formData.selectedServices.length > 0">
                    <tr>
                        <td class="table-title" colspan="2">Services</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ publicState.formData.selectedServices.join(', ') }}</td>
                    </tr>
                </template>
                
                <!-- Comments -->
                <template v-if="publicState.formData.comment !== ''">
                    <tr>
                        <td class="table-title" colspan="2">Comments</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ publicState.formData.comment }}</td>
                    </tr>
                </template>
                
                <!-- Time -->
                <template v-if="publicState.scheduledTime !== ''">
                    <tr>
                        <td class="table-title" colspan="2">Time</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ formatDateTime(publicState.scheduledTime) }}</td>
                    </tr>
                </template>

                <!-- Cost -->
                <template v-if="publicState.invoicePrice !== null && publicState.validPrice">
                    <tr>
                        <td class="table-title" colspan="2">Cost</td>
                    </tr>
                    <tr>
                        <td colspan="2">${{ publicState.invoicePrice / 100 }}<span v-if="'Tow' in publicState.formData.selectedServices"> (estimate -- ${{ (publicState.invoicePrice * 1.75) / 100 }} held until charge cleared)</span></td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { publicState } from  '@/pubstore.js';

export default {
    setup() {
        return {
            publicState
        }
    },
    methods: {
        formatDateTime(isoString) {
            const date = new Date(isoString);
            return date.toLocaleString(undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });
        }
    }
};
</script>

<style scoped>
body {
    background-color: #48a8dc;
    height: 100%;
}


.styled-table {
    border-collapse: separate;
    border-spacing: 0;
    font-size: 16px;
    width: 100%;
}

.styled-table tbody tr:first-child td:first-child {
    border-top-left-radius: 5px;
}

.styled-table tbody tr:first-child td:last-child {
    border-top-right-radius: 5px;
}

.styled-table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
}

.styled-table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
}

.styled-table th, .styled-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #48a8dc;
}

td {
    background-color: rgba(255, 255, 255, 1);
}

.table-title {
    background-color: #48a8dc;
    color: #ffffff;
    text-align: left;
}

.titleText {
  font-size: 2em;
  font-weight: 800;
  text-align: left;
  font-family: 'Prompt', sans-serif;
  display: flow;
  margin-top: 0;
  margin-bottom: 5px;
}

.response-message {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 767px) {
  .invoice-container {
    max-width: 100%;
  }
}
</style>