<template>
    <div class="form-container">
        <h1>ARS Job Portal</h1>
        <h2>Motorist information</h2>
        <p>Please fill in the form below with the motorist information.</p>
        <hr />
        <form @submit.prevent="submitForm">
            <div class="form-row">
                <div class="form-field-addr">
                    <label for="serviceAddress">Service Address</label>
                    <GMapAutocomplete
                        placeholder="Enter motorist location"
                        @place_changed="setPlace"
                        type="text"
                        class="text-wide"
                    >
                    </GMapAutocomplete>
                </div>
                <div class="form-field">
                    <span v-if="isZipValidating">⏳ Validating location...</span>
                    <span v-else-if="zipValidationResult === true">✅ {{ formData.market }}
                        <div class="form-field" v-if="techsLoaded">
                            Available Techs: {{ numTechs }}
                        </div>
                        <div class="form-field" v-if="etaLoaded">
                            Market average: {{ avgEta }} min
                        </div>
                    </span>
                    <span v-else-if="zipValidationResult === false">❌ Not in coverage area</span>
                    <span v-else-if="formData.city !== '' && formData.zip === ''">❌ Address needs a zip</span>
                </div>
            </div>

            <div class="form-row-veh">
                <div class="form-field">
                    <label for="vehicleMake">Make</label>
                    <select id="vehicleMake" v-model="formData.vehicleMake" class="dropdown">
                        <option v-for="make in vehicleData.filter(make => make.label !== 'Other')" :key="make.value" :value="make.value">{{ make.label }}</option>
                    </select>
                </div>
                <div v-if="selectedMake" class="form-field">
                    <label for="vehicleModel">Model</label>
                    <select id="vehicleModel" v-model="formData.vehicleModel" class="dropdown">
                        <option v-for="model in selectedMake.children" :key="model.value" :value="model.value">{{ model.label }}</option>
                    </select>
                </div>
                <div v-if="selectedModel" class="form-field">
                    <label for="vehicleYear">Year</label>
                    <select id="vehicleYear" v-model="formData.vehicleYear" class="dropdown">
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                    </select>
                </div>
            </div>
            <div class="form-row-veh">
                <div class="form-field">
                    <label for="vehicleColor">Vehicle color</label>
                    <input type="text" id="vehicleColor" v-model="formData.vehicleColor">
                </div>
            </div>

            <div class="form-row">
                <div class="checkbox-group">
                    <label class="custom-checkbox">
                        <input type="checkbox" value="Jump Start" v-model="formData.selectedServices" />
                        <span class="checkbox-label">Jump Start</span>
                    </label>
                    <label class="custom-checkbox">
                        <input type="checkbox" value="Lock Out" v-model="formData.selectedServices" />
                        <span class="checkbox-label">Lock Out</span>
                    </label>
                    <label class="custom-checkbox">
                        <input type="checkbox" value="Tire Change" v-model="formData.selectedServices" />
                        <span class="checkbox-label">Tire Change</span>
                    </label>
                    <label class="custom-checkbox">
                        <input type="checkbox" value="Fuel Delivery" v-model="formData.selectedServices" />
                        <span class="checkbox-label">Fuel Delivery</span>
                    </label>
                </div>
                <div class="form-field">
                    <span v-if="isResValidating">⏳ Validating vehicle...</span>
                    <span v-else-if="isResValid === true && formData.vehicleModel !== '' && formData.vehicleYear !== '' && formData.selectedServices.length > 0">✅ Valid vehicle</span>
                    <span v-else-if="isResValid === false">❌ Invalid service request</span>
                </div>
            </div>

            <div class="form-row">
                <div class="form-field">
                    <label for="motoristFirstName">Motorist first name</label>
                    <input type="text" id="motoristFirstName" v-model="formData.motoristFirstName">
                </div>
                <div class="form-field">
                    <label for="motoristLastName">Motorist last name</label>
                    <input type="text" id="motoristLastName" v-model="formData.motoristLastName">
                </div>
            </div>

            <div class="form-row">
                <div class="form-field">
                    <label for="motoristCell">Motorist cell</label>
                    <input type="text" v-model="formData.motoristCell">
                </div>
                <div class="form-field">
                    <label for="highwayCall">Vehicle on highway?</label>
                    <select id="highwayCall" v-model="formData.highwayCall" class="dropdown">
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
            </div>

            <div class="form-row">
                <div class="form-field">
                    <label for="comment">Comments</label>
                    <textarea id="comment" v-model="formData.comment" class="textarea-wide" placeholder="(Optional)"></textarea>
                </div>
            </div>

            <div class="button-container">
                <button @click="resetFormData" class="reset-button">
                    Reset form
                </button>
                <button type="submit"
                        :disabled="!isFormValid || isSubmitted"
                        :class="{'button-enabled': isFormValid && !isSubmitted, 'button-disabled': !isFormValid || isSubmitted}"
                        class="create-button">
                    Create Job
                </button>
            </div>
        </form>
        <div class="response-message">
            {{ responseMessage }}
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { GMapAutocomplete } from '@fawmi/vue-google-maps';
import { ref } from 'vue';

export default {
    components: {
        GMapAutocomplete
    },

    setup() {
        const numTechs = ref(0);
        const avgEta = ref(0);
        const etaLoaded = ref(false);
        const techsLoaded = ref(false);
        const formData = ref({
                serviceAddress: '',
                street: '',
                city: '',
                state: '',
                zip: '',
                market: '',
                selectedServices: [],
                vehicleYear: '',
                vehicleMake: '',
                vehicleModel: '',
                vehicleColor: '',
                motoristFirstName: '',
                motoristLastName: '',
                motoristCell: '',
                motoristEmail: '',
                highwayCall: '',
                googleAddress: '',
                latitude: '',
                longitude: '',
                comment: '',
            });
        const vehicleData = ref([]);
        const validZip = ref(null);
        const validVeh = ref(null);
        const validSvc = ref(null);
        const isZipValidating = ref(false);
        const zipValidationResult = ref(null);
        const isResValidating = ref(false);
        const isResValid = ref(null);
        const isSubmitted = ref(false);
        const responseMessage = ref('');

        return {
            numTechs,
            avgEta,
            formData,
            vehicleData,
            validZip,
            validVeh,
            validSvc,
            isZipValidating,
            zipValidationResult,
            isResValidating,
            isResValid,
            isSubmitted,
            responseMessage,
            etaLoaded,
            techsLoaded
        };
    },

    mounted() {
        this.fetchVehicleData();
        this.resetFormData();
    },
    methods: {
        async submitForm() {
            if (this.isFormValid && !this.isSubmitted) {
                this.isSubmitted = true;
                this.responseMessage = `Creating job...`;
                const payload = {
                    make: this.formData.vehicleMake,
                    model: this.formData.vehicleModel,
                    year: this.formData.vehicleYear,
                    color: this.formData.vehicleColor,
                    services: this.formData.selectedServices,
                    street: this.formData.street,
                    city: this.formData.city,
                    state: this.formData.state,
                    zip: this.formData.zip,
                    market: this.formData.market,
                    motoristFirst: this.formData.motoristFirstName,
                    motoristLast: this.formData.motoristLastName,
                    motoristCell: this.formData.motoristCell,
                    motoristEmail: this.formData.motoristEmail,
                    highway: this.formData.highwayCall,
                    googleAddress: this.formData.googleAddress,
                    latitude: this.formData.latitude,
                    longitude: this.formData.longitude,
                    comment: this.formData.comment,
                    provider: 'ARS',
                };
                try {
                    const response = await axios.post('/client/job/', payload);
                    console.log("Response received:", response.data);
                    if (response.data.valid) {
                        this.responseMessage = `✅ Job submitted to ARS`;
                    } else {
                        this.responseMessage = `❌ Unable to submit job: ${response.data.error}`;
                    }
                } catch (error) {
                    console.error("Error in submitForm:", error);
                    this.responseMessage = `❌ Error submitting job: ${error.message}`;
                }
            }
        },

        setPlace(place) {
            if (!place.geometry) {
                console.error('No details available for input: ' + place.name);
                return;
            }
            this.formData.street = '';
            this.formData.city = '';
            this.formData.state = '';
            this.formData.zip = '';
            this.formData.googleAddress = '';
            this.formData.latitude = '';
            this.formData.longitude = '';
            let street = '';
            let city = '';
            let state = '';
            let zip = '';
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case 'street_number':
                        street = `${component.long_name} ${street}`;
                        break;
                    case 'route':
                        street += component.short_name;
                        break;
                    case 'locality':
                        city = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        state = component.short_name;
                        break;
                    case 'postal_code':
                        zip = component.long_name;
                        break;
                }
            }
            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();
            this.formData.street = street;
            this.formData.city = city;
            this.formData.state = state;
            this.formData.zip = zip;
            this.formData.googleAddress = place.formatted_address;
            this.formData.latitude = latitude;
            this.formData.longitude = longitude;
        },
        async fetchVehicleData() {
            try {
                const response = await axios.get('/veh');
                if (response.data.code === "000000") {
                    this.vehicleData = response.data.data;
                } else {
                    console.error('Invalid response fetching the vehicle data');
                }
            } catch (error) {
                console.error('There was an error fetching the vehicle data:', error);
            }
        },
        async loadMarketData() {
            try {
                const payload = { market: this.formData.market };
                const responseEta = await axios.post('/eta', payload);
                if (responseEta.data.valid) {
                    this.avgEta = responseEta.data.eta;
                } else {
                    this.avgEta = responseEta.data.eta;
                }
                this.etaLoaded = responseEta.data.valid;
                const responseTechs = await axios.post('/techs', payload);
                if (responseTechs.data.valid) {
                    this.numTechs = responseTechs.data.techs;
                } else {
                    this.numTechs = responseTechs.data.techs;
                }
                this.techsLoaded = responseTechs.data.valid;
            } catch (error) {
                console.error("Error loading market data:", error);
                this.marketLoaded = false;
            }
        },
        async validateZip(zipCode) {
            this.marketLoaded = false;
            this.isZipValidating = true;
            this.zipValidationResult = null;

            try {
                const response = await axios.get(`/zip?zip=${zipCode}`);
                this.zipValidationResult = response.data.valid;
                this.formData.market = response.data.market;
            } catch (error) {
                console.error('Error validating zip code:', error);
            }
            this.isZipValidating = false;
            if (this.zipValidationResult) {
                this.loadMarketData()
            }
        },
        async validateRestrictions() {
            if (this.isFormDataComplete) {
                this.isResValidating = true;
                const payload = {
                    make: this.formData.vehicleMake.trim(),
                    model: this.formData.vehicleModel.trim(),
                    year: this.formData.vehicleYear.trim(),
                    services: this.formData.selectedServices,
                };
                try {
                    const response = await axios.post('/restriction', payload);
                    if (response.data.make === payload.make &&
                        response.data.model === payload.model &&
                        response.data.year === payload.year &&
                        this.arraysEqual(response.data.services, payload.services)) {
                            this.isResValid = response.data.valid;
                        }
                } catch (error) {
                    console.error('Error validating restrictions:', error);
                } finally {
                    this.isResValidating = false;
                }
            }
        },
        arraysEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false;
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false;
            }
            return true;
        },
        resetFormData() {
            this.formData.serviceAddress = '';
            this.formData.street = '';
            this.formData.city = '';
            this.formData.state = '';
            this.formData.zip = '';
            this.formData.market = '';
            this.formData.selectedServices = [];
            this.formData.vehicleYear = '';
            this.formData.vehicleMake = '';
            this.formData.vehicleModel = '';
            this.formData.vehicleColor = '';
            this.formData.motoristFirstName = '';
            this.formData.motoristLastName = '';
            this.formData.motoristCell = '';
            this.formData.motoristEmail = '';
            this.formData.highwayCall = '';
            this.formData.googleAddress = '';
            this.formData.latitude = '';
            this.formData.longitude = '';
            this.formData.comment = '';
            this.vehicleData = [];
            this.validZip = null;
            this.validVeh = null;
            this.validSvc = null;
            this.isZipValidating = false;
            this.zipValidationResult = null;
            this.isResValidating = false;
            this.isResValid = null;
            this.isSubmitted = false;
            this.responseMessage = '';
        }
    },
    computed: {
        isFormDataComplete() {
            return this.formData.vehicleYear && this.formData.vehicleMake && this.formData.vehicleModel && this.formData.selectedServices.length > 0;
        },
        selectedMake() {
            return this.vehicleData.find(make => make.value === this.formData.vehicleMake);
        },
        selectedModel() {
            return this.selectedMake ? this.selectedMake.children.find(model => model.value === this.formData.vehicleModel) : null;
        },
        isFormValid() {
            const hasRequiredFields = this.formData.street &&
                                      this.formData.city &&
                                      this.formData.state &&
                                      this.formData.zip &&
                                      this.formData.vehicleYear &&
                                      this.formData.vehicleMake &&
                                      this.formData.vehicleModel &&
                                      this.formData.motoristFirstName &&
                                      this.formData.motoristLastName &&
                                      this.formData.highwayCall !== '' &&
                                      this.formData.motoristCell &&
                                      this.formData.selectedServices.length > 0;
            return this.isResValid && this.zipValidationResult && hasRequiredFields;
        },
        years() {
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let year = currentYear + 1; year >= 1970; year--) {
                years.push(`${year}`);
            }
            return years;
        },
    },
    watch: {
        'formData.zip': function(newZip) {
            if (newZip.length === 5 && /^\d+$/.test(newZip)) {
                this.validateZip(newZip);
            } else {
                this.zipValidationResult = null;
            }
        },
        'formData.vehicleYear': 'validateRestrictions',
        'formData.vehicleMake': 'validateRestrictions',
        'formData.vehicleModel': 'validateRestrictions',
        'formData.selectedServices': 'validateRestrictions',
    },
};
</script>

<style scoped>
.form-container {
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  max-width: 20%; 
  min-width: 600px;
  background-color: #FAF8F8;
  margin: 20px auto;
  padding: 60px;
  padding-top: 10px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  box-shadow: 0 2px 4px hsla(0, 0%, 62%, 0.714);
}

.form-container form {
  text-align: left;
}

.form-container label, 
.form-container input,
.form-container select {
  display: block;
  box-sizing: border-box;
}

.form-row {
  width:auto;
  display: flex;
}

.form-row-veh {
  width: auto;
  display: flex;
}

.form-field {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 30%;
  max-width: auto;
  min-width: 150px;
  box-sizing: border-box;
}

.form-field-addr {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  max-width: auto;
  min-width: 150px;
  box-sizing: border-box;
}

label {
    white-space: nowrap;
}

.dropdown {
    max-width: auto;
    min-width: 30%;
    position: relative;
}

input[type="text"],
select,
button {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; 
  margin-bottom: 10px;
}

.create-button {
    justify-content: right;
}

.reset-button {
    justify-content: left;
    background-color: #4682b4;
    color: white;
    padding: 15px;
}

.text-wide {
    width: 100%;
}

h1 {
    text-align: center;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

button {
  color: white;
  cursor: pointer;
  border: none;
  padding: 15px;
  transition: background-color 0.3s;
  justify-content: right;
}

.button-enabled {
    background-color: #4682b4;
    padding: 15px;
    color: white;
}

.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
    padding: 15px;
    cursor: not-allowed;
}

.response-message {
    margin-top: 10px;
    color: #333;
    font-size: 18px;
    text-align: center;
}

.textarea-wide {
  width: 100%;
  height: 100px;
  padding: 10px;
  box-sizing: border-box; /* Add this line to include padding and border in the total width */
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}

.checkbox-group {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 5px; 
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.custom-checkbox span.checkbox-label {
  pointer-events: none;
}

.custom-checkbox input[type="checkbox"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-checkbox .checkbox-label {
  display: inline;
}
</style>