<template>
  <Header />
  <div class="location-container">
    <div class="form-container">
      <div class="location-request">
          <button v-if="!locationRequested" @click="getLocation">Share my location</button>
          <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
          <div v-if="successMessage" class="success">{{ successMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/public/AppHeader.vue';
export default {
  name: 'LocationView',
  components: { Header},
  data() {
    return {
      locationRequested: false,
      errorMessage: '',
      successMessage: '',
    };
  },
  methods: {
    getLocation() {
      if (navigator.geolocation) {
        this.locationRequested = true;
        navigator.geolocation.getCurrentPosition(this.positionSuccess, this.positionError);
      } else {
        this.errorMessage = 'Geolocation is not supported by this browser.';
      }
    },
    positionSuccess(position) {
      const locInfo = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        slug: this.$route.query.slug,
      };
      fetch('/public/location/request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(locInfo),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Result:', data);
        if (!data.valid) {
            this.errorMessage = `Failed to set location`;
        } else {
            this.successMessage = `Submitted your location:\n ${data.address} (${data.lat}/${data.lon})`
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        this.errorMessage = `Failed to set location: ${error}`;
      });
    },
    positionError(error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          this.errorMessage = "Browser denied the request for geolocation. Please make sure your browser allows location sharing.";
          break;
        case error.POSITION_UNAVAILABLE:
          this.errorMessage = "Location information is unavailable.";
          break;
        case error.TIMEOUT:
          this.errorMessage = "The request to get user location timed out.";
          break;
        case error.UNKNOWN_ERROR:
          this.errorMessage = "An unknown error occurred.";
          break;
      }
      this.locationRequested = false;
    },
  },
};
</script>

<style scoped>

.location-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background: #f9f9f9;
  }
.form-container {
  text-align: center;
  padding: 15px;
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #f9f9f9;
  border-radius: 15px;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
}

.location-request {
  display: flex;
  justify-content: center;
  align-items: center;
}

.location-request button {
  background-color: #48a8dc;
  color: #fff;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2rem;
  text-decoration: none;
  transition: background-color .3s, transform .2s;
  cursor: pointer;
  box-shadow: 0 20px 30px -10px rgba(24, 16, 99, .36);
}

.location-request button:hover {
  background-color: #5e5e5e;
    transform: translateY(-1px);
}

.location-request button:active {
  background-color: #5e5e5e;
    transform: translateY(-1px);
}

.location-request button:focus {
  outline: none;
}

.error {
  text-align: center;
  color: red;
  margin-top: 20px;
}

.success {
  text-align: center;
  font-weight: bold;
  color: hsl(224, 73%, 30%);
  margin-top: 20px;
}

.titleText {
    font-size: 2em;
    font-weight: 800;
    font-family: 'Prompt', sans-serif;
    display: block;
    margin-top: 0;
    margin-bottom: 5px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    height: 20px;
    padding-top: 20px;
    padding-bottom: 15px;
    background-color: #48a8dc;
  }

  body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    background-color: #7eb2cf;
  }

@media (max-width: 768px) {
  .location-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
  }
  .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 50px;
      height: 25px;
      padding-top: 25px;
      padding-bottom: 20px;
    }

    .body {
    margin-top: 7px;
    text-align: left;
    font-size: 1.3em;
    }

    .titleText {
    font-size: 2.2em;
    font-weight: 800;
    text-align: left;
    font-family: Prompt, sans-serif;
    display: flow;
    margin-top: 0;
    margin-bottom: 2.5px;
    }

    .form-container{
      display: flex;
      flex-direction: column;
      width: auto;
      position: relative;
      height: auto;
      flex-grow: 1;
      margin-top: 71px;
      padding: 20px;
      box-shadow: none; 
      border-radius: 0; 
    }
}
</style>