<template>
        <div class="form-field">
            <div class="form-row">
                <div class="form-field">
                    <textarea id="comment" v-model="comment" class="textarea-wide" placeholder="Customer comments"></textarea>
                </div>
            </div>
        </div>
</template>

<script>
import { computed } from 'vue';
import { scriptState } from  '@/store.js';
export default {
    setup() {
        const comment = computed({
            get: () => scriptState.formData.comment,
            set: value => { scriptState.formData.comment = value; }
        });
        return {
            comment,
        };
    },
    props: {
        label: String,
    },
}
</script>

<style scoped>

.form-row {
    width: 100%;
    /* display: flex; */
    /* justify-content: flex-start; */
    align-items: center;
    gap: 10px;
}

.form-field {
    font-size: 16px;
    align-items: center;
}

.textarea-wide {
    width: 100%;
    height: 80px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}
</style>