<template>
    <div class="fuel-container">
        <div class="form-field">
            <div class="form-row">
                <div class="radio-group">
                    <label class="custom-radio">
                        <input type="radio" name="fuelType" value="Unleaded" v-model="publicState.selectedFuel" @change="updateFieldValidity" />
                        <span class="radio-label">Unleaded</span>
                    </label>
                    <label class="custom-radio">
                        <input type="radio" name="fuelType" value="Diesel" v-model="publicState.selectedFuel" @change="updateFieldValidity" />
                        <span class="radio-label">Diesel</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { publicState } from '@/pubstore.js';

export default {
    setup() {
        return { publicState};
    },
    methods: {
        updateFieldValidity() {
            publicState.fieldsValid = true;
        }
    }
};
</script>

<style scoped>

.fuel-container {
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #ddd;
}

.radio-group {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
}

.custom-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-radio input[type="radio"]:checked {
  background-color: #48a8dc;
  border-color: #48a8dc;
}

.custom-radio span.radio-label {
  pointer-events: none;
}

.custom-radio input[type="radio"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-radio .radio-label {
  display: inline;
}
</style>
