<template>
  <div>
    <div class="header-image"></div>

    <div class="tab-bar">
      <button @click="currentTab = 'form'" :class="{ active: currentTab === 'form' }">Job form</button>
      <button @click="currentTab = 'db'" :class="{ active: currentTab === 'db' }">Job history</button>
      <button v-if="isAdmin" @click="currentTab = 'guest'" :class="{ active: currentTab === 'guest' }">Client users</button>
    </div>

    <ClientFormComponent v-if="currentTab === 'form'" />
    <ClientDbComponent v-if="currentTab === 'db'" />
    <ClientGuestComponent v-if="currentTab === 'guest'" />
  </div>
</template>

<script>
import ClientFormComponent from '../components/client/ClientFormComponent.vue';
import ClientGuestComponent from '../components/client/ClientGuestComponent.vue';
import ClientDbComponent from '../components/client/ClientDbComponent.vue';
import { ref } from 'vue';

export default {
  components: {
    ClientFormComponent,
    ClientDbComponent,
    ClientGuestComponent
  },
  mounted() {
    this.checkAuthStatus();
  },
  data() {
    const isStaging = window.location.hostname.includes('staging');
    const isAdmin = ref(false);
    return {
      currentTab: 'form',
      isStaging,
      isAdmin,
    };
  },
  methods: {
    checkAuthStatus() {
      setInterval(async () => {
        try {
          const response = await fetch('/authed');
          if (response.status === 200) {
            const data = await response.json();
            this.isAdmin.value = data.isAdmin;
          } else {
            window.location.reload();
          }
        } catch (error) {
          console.error('Error checking auth status:', error);
          window.location.reload();
        }
      }, 1800000);
    },
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  background-color: #7EB2CF;
}

.header-image {
  width: 100%; 
  height: 120px; 
  position: relative;
  overflow: hidden;
  background-image: url('../assets/header-image.png');
  background-size: cover;
  background-blend-mode: overlay;
}

.tab-bar {
  display: flex;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  justify-content: center;
  color: #fff;
  margin-top: 10px;
}

.tab-bar button {
  color: #fff;
  background-color: #6c8192;
  border-radius: 5px;
  border: 1px solid #356083;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-bar button:hover {
  background-color: #3b99e6;
}

.tab-bar button.active {
  background-color: #4682b4;
}
</style>