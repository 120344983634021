<template>
    <div class="form-field">
        <label for="motoristFirstName">First name </label>
        <input type="text" id="motoristFirstName" v-model="motoristFirstName" class="contact-field">
    </div>
    <div class="form-field">
        <label for="motoristLastName">Last name </label>
        <input type="text" id="motoristLastName" v-model="motoristLastName" class="contact-field">
    </div>
    <div class="form-field">
        <label for="motoristCell">Cell </label>
        <input type="text" v-model="motoristCell" class="contact-field">
    </div>
    <div class="form-field">
        <label for="motoristEmail">Email </label>
        <input type="email" id="motoristEmail" v-model="motoristEmail" placeholder="(Optional)" class="contact-field">
        <div v-if="!isEmailValid" class="error-message">Please enter a valid email address.</div>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { scriptState } from  '@/store.js';
export default {
    setup() {
        const motoristFirstName = computed({
            get: () => scriptState.formData.motoristFirstName,
            set: value => { scriptState.formData.motoristFirstName = value; }
        });
        const motoristLastName = computed({
            get: () => scriptState.formData.motoristLastName,
            set: value => { scriptState.formData.motoristLastName = value; }
        });
        const motoristCell = computed({
            get: () => scriptState.formData.motoristCell,
            set: value => {
                scriptState.formData.motoristCell = value.replace(/\D+/g, '');
            }
        });
        const motoristEmail = computed({
            get: () => scriptState.formData.motoristEmail,
            set: value => { scriptState.formData.motoristEmail = value; }
        });
        const isEmailValid = ref(true);
        const validateEmail = (email) => {
            if (email === '') {
                isEmailValid.value = true;
            } else {
                const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                isEmailValid.value = regex.test(email);
            }
        };
        watch(motoristEmail, (newValue) => {
            validateEmail(newValue);
        }, { immediate: true });
        return {
            motoristFirstName,
            motoristLastName,
            motoristCell,
            isEmailValid,
            motoristEmail
        };
    },
    props: {
        label: String,
    },
    computed: {
        cxFirstCompute() {
            return scriptState.formData.motoristFirstName;
        },
        cxLastCompute() {
            return scriptState.formData.motoristLastName;
        },
        cxCellCompute() {
            return scriptState.formData.motoristCell;
        },
        isContactComplete() {
            return scriptState.formData.motoristFirstName !== '' && scriptState.formData.motoristLastName !== '' && scriptState.formData.motoristCell !== '' && this.isEmailValid;
        },
    },
    methods: {
        async validateContact() {
            if ((this.isContactComplete) && (scriptState.formData.motoristCell.length > 9)) {
                scriptState.fieldsValid = true;
            }
        }
    },
    watch: {
        cxFirstCompute() {
            this.validateContact();
        },
        cxLastCompute() {
            this.validateContact();
        },
        cxCellCompute() {
            this.validateContact();
        }
    }
}
</script>

<style scoped>

.form-field {
    flex-grow: 1; 
    flex-shrink: 1; 
    flex-basis: 30%;
    max-width: 50%;
    min-width: 180px;
    align-items: center;
}

.contact-field {
    position: relative;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}

.form-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.error-message {
    color: #d12525;
    font-weight: bold;
    font-size: 12px;
}
</style>