<template>
    <div id="splash-screen">
        <img src="/icon-192x192.png" alt="ARS">
    </div>
    <div class="app">
        <StagingPopover />
        <div class="width-wrapper">
            <Header />
            <div class="content-wrapper">
                <div class="left-column">
                    <div class="non-map-container">
                        <LocationPopover v-if="showLocationPopover" @close="showLocationPopover = false" />
                                <Dialogue :titleText="current.currentDialogue['titleText']" 
                                        :dialogue="current.currentDialogue.dialogue" />
                                <Invoice v-if="publicState.showInvoiceComponent" />
                                <Options :options="current.currentDialogue.options"
                                        @selectOption="handleOptionSelect" />
                    </div>
                </div>
                <div class="right-column">
                    <div class="map-container">
                        <Map
                            :center="markerPosition"
                            :zoom="10"
                            map-type-id="roadmap"
                            class="map"
                        >
                            <Marker
                                :position="markerPosition"
                                :draggable="true"
                                @dragend="onMarkerDragEnd"
                            ></Marker>
                        </Map>
                   
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Dialogue from '@/components/public/PublicDialogue.vue';
import Options from '@/components/public/PublicOptions.vue';
import Invoice from '@/components/public/PublicInvoice.vue';
import { Map, Marker} from '@fawmi/vue-google-maps';//, GMapAutocomplete } from '@fawmi/vue-google-maps';
import Header from '@/components/public/AppHeader.vue';
import LocationPopover from '@/components/public/LocationPopover.vue';
import StagingPopover from '@/components/public/StagingPopover.vue';
import pubscript from '@/assets/public-script.json';
import { publicState, current } from '@/pubstore.js';
import { reactive, computed, ref  } from 'vue';
import axios from 'axios';

export default {
    props: {
        label: String,
    },
    components: { Dialogue, Options, Invoice, Header, LocationPopover, StagingPopover, Map, Marker}, //, GMapAutocomplete },
    data() {
        return {
            nodes: pubscript.nodes,
            showLocationPopover: true,
            currentNodeId: '1',
            defaultLocation: {
                lat: 32.7763, 
                lng: -96.7934
            },
            sharedState: reactive({
                stateHistory: [],
                updateStateHistory() {
                    const currentState = JSON.parse(JSON.stringify(publicState));
                    this.stateHistory.push(currentState);
                    history.pushState({ nodeId: current.currentDialogue.id, state: currentState }, '', '');
                }
            })
        };
    },
    provide() {
        return {
            sharedState: this.sharedState
        };
    },
    setup() {
        const avgEta = ref(0);
        const etaLoaded = ref(false);
        return {
            current,
            publicState,
            isZipValidating: computed(() => publicState.isZipValidating),
            zipValidationResult: computed(() => publicState.zipValidationResult),
            avgEta,
            etaLoaded
        }
    },
    mounted() {
        window.addEventListener('popstate', this.handlePopstate);
        this.fetchLocation();
        const splash = document.getElementById('splash-screen');
        if (splash) {
            setTimeout(() => {
                splash.style.display = 'none';
            }, 800);
        }
    },
    beforeUnmount() {
        window.removeEventListener('popstate', this.handlePopstate);
    },
    computed: {
        markerPosition() {
            const lat = publicState.formData.latitude;
            const lng = publicState.formData.longitude;
            if (this.isValidCoordinate(lat) && this.isValidCoordinate(lng)) {
                return {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                };
            }
            return this.defaultLocation;
        },
        currentNode() {
            return pubscript.nodes.find(node => node.id === this.currentNodeId);
        }
    },
    methods: {
        isValidCoordinate(coordinate) {
            return coordinate !== '' && !isNaN(parseFloat(coordinate)) && isFinite(coordinate);
        },
        updateAutocompleteValue(newValue) {
            const autocompleteInput = this.$refs.autocomplete?.$refs.input;
            if (autocompleteInput) {
                autocompleteInput.value = newValue;
            }
        },
        setPlace(place) {
            if (!place || !place.geometry) {
                console.error('No valid place data');
                return;
            }
            publicState.formData.street = '';
            publicState.formData.city = '';
            publicState.formData.state = '';
            publicState.formData.zip = '';
            publicState.formData.googleAddress = '';
            publicState.formData.latitude = '';
            publicState.formData.longitude = '';
            let localStreet = '';
            let localCity = '';
            let localState = '';
            let localZip = '';
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case 'street_number':
                        localStreet = `${component.long_name} ${localStreet}`;
                        break;
                    case 'route':
                        localStreet += component.short_name;
                        break;
                    case 'locality':
                        localCity = component.long_name;
                        break;
                    case 'administrative_area_level_1':
                        localState = component.short_name;
                        break;
                    case 'postal_code':
                        localZip = component.long_name;
                        break;
                }
            }
            publicState.formData.street = localStreet;
            publicState.formData.city = localCity;
            publicState.formData.state = localState;
            publicState.formData.zip = localZip;
            publicState.formData.googleAddress = place.formatted_address;
            this.updateAutocompleteValue(place.formatted_address);
            let latitude, longitude;
            if (typeof place.geometry.location.lat === 'function') {
                latitude = place.geometry.location.lat();
            } else {
                latitude = place.geometry.location.lat;
            }
            if (typeof place.geometry.location.lng === 'function') {
                longitude = place.geometry.location.lng();
            } else {
                longitude = place.geometry.location.lng;
            }
            if (this.isValidCoordinate(latitude) && this.isValidCoordinate(longitude)) {
                publicState.formData.latitude = latitude;
                publicState.formData.longitude = longitude;
            }
        },
        onMarkerDragEnd(event) {
            const newLat = event.latLng.lat();
            const newLng = event.latLng.lng();
            this.reverseGeocode(newLat, newLng);
        },

        async reverseGeocode(latitude, longitude) {
            try {
                const response = await fetch('/public/geocode', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ latitude, longitude }),
                });
                if (!response.ok) {
                    throw new Error('Reverse geocoding failed');
                }
                const place = await response.json();
                const routeIndex = place.results.findIndex(result => 
                    result.types && (result.types.includes('street_address') || result.types.includes('route'))
                );
                if (routeIndex !== -1) {
                    this.setPlace(place.results[routeIndex]);
                } else {
                    console.error('No result with type "route" found');
                    this.validateZip('');
                }
            } catch (error) {
                console.error('Error in reverse geocoding:', error);
            }
        },

        async loadMarketData() {
            try {
                const payload = { market: publicState.formData.market };
                const responseEta = await axios.post('/public/eta', payload);
                if (responseEta.data.valid) {
                    this.avgEta = responseEta.data.eta;
                } else {
                    this.avgEta = responseEta.data.eta;
                }
                this.etaLoaded = responseEta.data.valid;
            } catch (error) {
                console.error("Error loading market data:", error);
                this.etaLoaded = false;
            }
        },

        async validateZip(zipCode) {
            publicState.isZipValidating = true;
            publicState.zipValidationResult = null;
            try {
                const response = await axios.get(`/public/zip?zip=${zipCode}`);
                publicState.zipValidationResult = response.data.valid;
                publicState.fieldsValid = response.data.valid;
                publicState.formData.market = response.data.market;
                publicState.formData.provider = response.data.provider;
            } catch (error) {
                console.error('Error validating zip code:', error);
            }
            publicState.isZipValidating = false;
            this.loadMarketData();
        },
        
        closePopover() {
            this.$emit('close');
        },

        fetchLocation() {
            axios.post('/public/loc', {})
                .then(response => {
                    if (response.data.valid && response.data.lat !== 0 && response.data.lon !== 0) {
                        this.defaultLocation = {
                            lat: response.data.lat,
                            lng: response.data.lon
                        };
                    }
                })
                .catch(error => {
                    console.error('Error fetching location:', error);
                });
        },
        handleOptionSelect(nextNodeId) {
            const nextNode = pubscript.nodes.find(node => node.id === nextNodeId);
            if (nextNode) {
                current.currentDialogue = nextNode;
            }
        },
        handlePopstate(event) {
            console.log('Popstate event state:', event.state);
            if (event.state && event.state.nodeId) {
                const nodeId = event.state.nodeId;
                const node = this.nodes.find(n => n.id === nodeId);
                if (node) {
                    this.currentNodeId = nodeId;
                    current.currentDialogue = node;
                    if (event.state.state) {
                        this.restoreState(event.state.state);
                    }
                }
            }
        },
        restoreState(previousState) {
            if (previousState) {
                Object.keys(previousState).forEach(key => {
                    publicState[key] = previousState[key];
                });
            } else {
                console.warn("No state to restore");
            }
        }
    }
};
</script>

<style scoped>
body {
    background-color: #fff;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    padding-bottom: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: #48a8dc;
    height: 25px;
    padding-top: 30px;
    padding-bottom: 25px;
}


.non-map-container {
    padding: 20px;
    position: relative;
    max-height: calc(100vh - 80px);
}

.map-container{
    height: 100%;
}
.map {
        height: 100%;

}

.app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    background: #f9f9f9;
    background-size: cover;
}

.width-wrapper {
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    min-height: 100vh;
    height: auto;
}

.content-wrapper {
    display: flex;
    flex-direction: row;
    width: auto;
    position: relative;
    flex-grow: 1;
    margin-top: 80px;
    height: calc(100vh - 80px);
    background-color: #FFFFFF;
}

.left-column {
    display: flex;
    flex-direction: column;
    width: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}


.right-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.width-wrapper {
    position: relative;
}

#splash-screen {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
#splash-screen img {
    max-width: 256px;
    max-height: 256px;
}

@media (max-width: 1350px) {
    .header {
    height: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

 .content-wrapper{
    margin-top: 60px;
    height: calc(100vh - 60px);
 }

}

@media (max-width: 768px) {
    .non-map-container {
        position: static;
        padding: 0px;
}
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 50px;
        padding-bottom: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 25px;
        padding-top: 25px;
        padding-bottom: 20px;
    }

    body {
        background-color: #48a8dc;
        height: 100%;
}

    .app {
        display: flex;
        align-items: center;
        flex-direction: column;
        min-height: 100vh;
        background: #f9f9f9;
        background-size: cover;
    }

    .width-wrapper {
        width: 100%;
        margin: 0 auto;
        position: relative;
        top: 0px;
        left: 0px;
        right: 0px;
        min-height: 100vh;
        height: auto;
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        width: auto;
        position: relative;
        height: auto;
        flex-grow: 1;
        margin-top: 71px;
        border-radius: 10px;
        padding: 20px;
        background-color: #FFFFFF;
    }

    .left-column {
        order: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .right-column {
        display: none;
    }

    .width-wrapper {
        position: relative;
    }
}
</style>
