import { reactive } from 'vue';
import script from '@/assets/public-script.json';

// for autorescues.com form
export const publicState = reactive({
    invoiceData: false,
    fieldsValid: true,
    formData: {
        serviceAddress: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        market: '',
        googleAddress: '',
        latitude: '',
        longitude: '',
        selectedServices: [],
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vehicleColor: '',
        motoristFirstName: '',
        motoristLastName: '',
        motoristCell: '',
        motoristEmail: '',
        highwayCall: '',
        comment: '',
        provider: '',
    },
    nodeHistory: ["1"],
    vehicleData: [],
    validZip: null,
    validVeh: null,
    validSvc: null,
    isZipValidating: false,
    zipValidationResult: null,
    isResValidating: false,
    isResValid: null,
    isSubmitted: false,
    responseMessage: '',
    checkoutUrl: '',
    invoicePrice: null,
    validPrice: false,
    selectedFuel: '',
    scheduledTime: '',
    selectedSide: [],
    hwySide: '',
    showInvoiceComponent: false,
    showCsosComponent: false
});

export function resetPublicState() {
    publicState.invoiceData = false;
    publicState.fieldsValid = true;
    publicState.nodeHistory = ["1"],
    publicState.formData.serviceAddress = '';
    publicState.formData.street = '';
    publicState.formData.city = '';
    publicState.formData.state = '';
    publicState.formData.zip = '';
    publicState.formData.market = '';
    publicState.formData.googleAddress = '';
    publicState.formData.latitude = '';
    publicState.formData.longitude = '';
    publicState.formData.selectedServices = [];
    publicState.formData.vehicleYear = '';
    publicState.formData.vehicleMake = '';
    publicState.formData.vehicleModel = '';
    publicState.formData.vehicleColor = '';
    publicState.formData.motoristFirstName = '';
    publicState.formData.motoristLastName = '';
    publicState.formData.motoristCell = '';
    publicState.formData.motoristEmail = '';
    publicState.formData.highwayCall = '';
    publicState.formData.comment = '';
    publicState.formData.provider = '';
    publicState.isZipValidating = false;
    publicState.zipValidationResult = null;
    publicState.vehicleData = [];
    publicState.validZip = null;
    publicState.validVeh = null;
    publicState.validSvc = null;
    publicState.isResValidating = false;
    publicState.isResValid = null;
    publicState.isSubmitted = false;
    publicState.responseMessage = '';
    publicState.checkoutUrl = '';
    publicState.invoicePrice = null;
    publicState.validPrice = false;
    publicState.selectedFuel = '';
    publicState.scheduledTime = '';
    publicState.selectedSide = [];
    publicState.hwySide = '';
    publicState.showInvoiceComponent = false;
    publicState.showCsosComponent = false;
}

export const current = reactive({
    currentDialogue: script.nodes[0],
  });