<template>
  <div class="editor">
    <div class="json-panel">
        <button @click="handleUpdateClick" 
            :disabled="isCurrentVersionRendered"
            :class="{'button-enabled': !isCurrentVersionRendered, 'button-disabled': isCurrentVersionRendered}">
            Re-render</button>
        <div v-if="jsonErr" class="error-alert">⚠️ Invalid JSON</div>
        <textarea v-model="textareaContent"></textarea>
    </div>
    <div class="graph-panel" ref="graphContainer"></div>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue';
import callScript from '@/assets/public-script.json';
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';

export default {
  setup() {
    const textareaContent = ref(JSON.stringify(callScript, null, 2));
    const graphContainer = ref(null);
    const nodes = new DataSet([]);
    const edges = new DataSet([]);
    let jsonErr = ref(false);

    const updateGraph = (jsonString) => {
        try {
            const data = JSON.parse(jsonString);
            nodes.clear();
            edges.clear();
            data.nodes.forEach(node => {
                nodes.add({
                    id: node.id,
                    // label: node.dialogue,
                    label: `${node.titleText} - ${node.dialogue}`,
                    // color: (!node.type || node.type === 'dialogue') ? '#4682b4' : '#006400',
                    color: '#4682b4',
                    shape: 'box',
                    widthConstraint: { maximum: 200 }
                });
                if (node.options && Array.isArray(node.options)) {
                    node.options.forEach((option, index) => {
                    const optionId = `${node.id}_option_${index}`;
                    nodes.add({
                        id: optionId,
                        label: option.text,
                        // color: (!option.type || option.type === 'dialogue') ? '#99b3c9' : '#006400',
                        color: '#99b3c9',
                        shape: 'box',
                        widthConstraint: { maximum: 200 }
                    });
                    edges.add({ from: node.id, to: optionId });

                    if (option.nextNodeId) {
                        edges.add({ from: optionId, to: option.nextNodeId });
                    }
                    });
                }
            });
            jsonErr.value = false;
        } catch (e) {
            console.error('Invalid JSON:', e);
            jsonErr.value = true;
        }
    };

    const handleUpdateClick = () => {
      updateGraph(textareaContent.value);
    };

    const isCurrentVersionRendered = computed(() => {
      try {
        const currentGraphData = JSON.stringify(JSON.parse(textareaContent.value), null, 2);
        return currentGraphData === JSON.stringify(callScript, null, 2);
      } catch (e) {
        return false;
      }
    });

    onMounted(() => {
        const graphOptions = {
            nodes: {
                shape: 'box',
                margin: 5,
                font: { color: '#fff' },
                widthConstraint: { maximum: 200 },
            },
            edges: {
                arrows: 'to'
            },
            layout: {
                improvedLayout: true,
                hierarchical: {
                    enabled: true,
                    direction: 'UD',
                    levelSeparation: 150,
                    edgeMinimization: true,
                    nodeSpacing: 300,
                    treeSpacing: 50
                }
            },
            physics: {
                enabled: false
            }
        }
        new Network(graphContainer.value, { nodes, edges }, graphOptions);
        updateGraph(JSON.stringify(callScript, null, 2));
    });
    watch(updateGraph);
    return { graphContainer, jsonErr, handleUpdateClick, isCurrentVersionRendered, textareaContent };
  },
};
</script>

<style>
.editor {
  display: flex;
  margin: 20px auto;
  font-family: 'Nunito', sans-serif;
  font-weight: 200;
  min-width: 60%;
  background-color: #FAF8F8;
  padding: 30px;
  padding-top: 10px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  height: 600px;
  box-shadow: 0 2px 4px hsla(0, 0%, 62%, 0.714);
}

.json-panel textarea {
  width: 600px;
  height: 90%;
}

.graph-panel {
  width: 100%;
  height: 100%;
}

.error-alert {
    font-weight: bold;
    color: #701818;
    border-radius: 3px;
    border: 1px solid #701818;
    font-size: 14px;
    text-align: center;
}

button {
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc; 
  margin-bottom: 10px;
}

.button-enabled {
    background-color: #4682b4;
    color: white;
}

.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
    cursor: not-allowed;
}
</style>
