<template>
    <div class="options">
        <div v-if="current.currentDialogue.fields" class="fields-container">
            <div v-for="field in current.currentDialogue.fields" :key="field.type">
                <template v-if="field.type === 'comment-field'">
                    <CommentField />
                </template>
                <template v-if="field.type === 'veh-field'">
                    <VehicleField />
                </template>
                <template v-if="field.type === 'contact-field'">
                    <ContactField />
                </template>
                <template v-if="field.type === 'invoice-button'">
                    <InvoiceButton />
                </template>
                <template v-if="field.type === 'get-price'">
                    <GetPrice />
                </template>
                <template v-if="field.type === 'fuel-field'">
                  <FuelSelect />
                </template> 
                <template v-if="field.type === 'schedule-field'">
                  <PublicSchedule />
                </template> 
                <template v-if="field.type === 'side-field'">
                  <SideSelect />
                </template>
                <template v-if="field.type === 'csos-redirect'">
                  <CsosRedirect />
                </template>
            </div>
        </div>
        <div>
            <span v-if="publicState.fieldsValid">
                <button 
                    v-for="option in options" 
                    :key="option.nextNodeId"
                    @click="selectOption(option.nextNodeId)"
                    :class="{ selected: selectedOption === option.nextNodeId, faded: selectedOption && selectedOption !== option.nextNodeId }"
                    :disabled="!isOptionSelectable"
                >
                    {{ option.text }}
                </button>
            </span>
            <button
                v-if="publicState.nodeHistory && publicState.nodeHistory.length !== 1"
                @click="restart"
                class="restart-button"
            >
                Restart
            </button>
        </div>

    </div>
</template>

<script>
import { ref } from 'vue';
import { publicState, current } from  '@/pubstore.js';
import VehicleField from '@/components/public/VehicleField.vue';
import InvoiceButton from '@/components/public/InvoiceButton.vue';
import ContactField from '@/components/public/ContactField.vue';
import CommentField from '@/components/public/CommentField.vue';
import CsosRedirect from '@/components/public/PublicRedirect.vue';
import GetPrice from '@/components/public/GetPrice.vue';
import FuelSelect from '@/components/public/FuelSelect.vue';
import SideSelect from '@/components/public/SideSelect.vue';
import PublicSchedule from '@/components/public/PublicSchedule.vue';
import pubscript from '@/assets/public-script.json';

export default {
    props: ['options'],
    inject: ['sharedState'],
    components: {
        VehicleField,
        ContactField,
        InvoiceButton,
        CommentField,
        GetPrice,
        FuelSelect,
        PublicSchedule,
        SideSelect,
        CsosRedirect,
    },
    data() {
        return {
            isOptionSelectable: true,
        };
    },
    setup() {
        const selectedOption = ref(null);
        function updateState(metadata) {
            metadata.forEach(item => {
                const keys = item.key.split('.');
                let ref = publicState;
                for (let i = 0; i < keys.length - 1; i++) {
                    ref = ref[keys[i]];
                }
                if (Array.isArray(ref[keys[keys.length - 1]])) {
                    ref[keys[keys.length - 1]].push(item.value);
                } else {
                    ref[keys[keys.length - 1]] = item.value;
                }
            });
        }

        return { pubscript, publicState, updateState, current, selectedOption };
    },
    methods: {
        selectOption(nextNodeId) {
            if (!this.isOptionSelectable) return;
            this.isOptionSelectable = false;
            this.sharedState.updateStateHistory();
            this.selectedOption = nextNodeId;
            const nextNode = pubscript.nodes.find(node => node.id === nextNodeId);
            if (nextNode) {
                publicState.nodeHistory.push(nextNodeId);
                if (nextNode.state && Array.isArray(nextNode.state)) {
                    this.updateState(nextNode.state);
                }
                if (nextNode.actions && Array.isArray(nextNode.actions)) {
                    nextNode.actions.forEach(action => {
                        switch (action) {
                            case 'send-ars-invoice':
                                this.submitForm();
                                break;
                            case 'reset-state':
                                publicState.resetPublicState();
                                break;
                        }
                    });
                }
            }
            this.$emit('selectOption', nextNodeId);
            // noninteractive for 200ms to avoid fatfingering
            setTimeout(() => {
                this.isOptionSelectable = true;
            }, 200);
        },
        restart() {
            location.reload();
        }
    }
};
</script>

<style scoped>
.options {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1; /* Take up remaining vertical space */
}

.restart-button {
  color: #fff;
  text-align: center;
  background-color: #000000 !important; /* Ensure black background */
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2rem;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  box-shadow: 0 20px 30px -10px rgba(24, 16, 99, 0.36);
  margin-top: auto;
}

.options button {
  color: #fff;
  text-align: center;
  background-color: #48a8dc;
  border-radius: 5px;
  padding: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 2rem;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  box-shadow: 0 20px 30px -10px rgba(24, 16, 99, 0.36);
  width: 100%;
  margin-bottom: 10px; 
}

.options button.selected {
  opacity: 1;
}

.options button:hover {
  background-color: #5e5e5e;
  transform: translateY(-1px);
}

@media (min-width: 1200px) {
    .options button {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
.options {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  width: auto;
}



.options-container {
    flex: 1;
    margin: 10px;
    max-width: 700px;
    min-width: 300px;
    width: auto;
    border-radius: 10px;
}

.options button {
    color: #fff;
    text-align: center;
    background-color: #48a8dc;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6em;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
    box-shadow: 0 20px 30px -10px rgba(24, 16, 99, .36);
    width: 100%;
}

.options button.selected {
  opacity: 1;
}

.options button:hover {
    background-color: #5e5e5e;
    transform: translateY(-1px);
}
}

.restart-button:hover {
    background-color: #5e5e5e;
    transform: translateY(-1px);
}

@media (min-width: 1600px) {
        .options button {
            font-size: 1.2em;
    }
}

@media (hover: none) and (pointer: coarse) {
  .options button:hover {
    background-color: #48a8dc;
    transform: none;
  }
}

</style>


