import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/views/LoginPage.vue';
import ClientLoginPage from '@/views/ClientLoginPage.vue';
import MainApp from '@/views/MainApp.vue';
import ClientApp from '@/views/ClientApp.vue';
import SuccessView from '@/views/SuccessView.vue';
import CancelView from '@/views/CancelView.vue';
import PublicView from '@/views/PublicView.vue';
import LocationView from '@/views/LocationView.vue';

const DEFAULT_TITLE = 'Auto Rescue Solutions';

function getRootComponent() {
    const host = window.location.hostname;
    if (host.endsWith('location.autorescues.com')) {
        return LocationView;
    } else if (host.endsWith('client.autorescuesolutions.com')) {
        return ClientApp;
    } else if (host.endsWith('autorescuesolutions.com')) {
        return MainApp;
    } else if (host.endsWith('success.autorescues.com')) {
        return SuccessView;
    } else if (host.endsWith('cancel.autorescues.com')) {
        return CancelView;
    } else if (host.endsWith('autorescues.com')) {
        return PublicView;
    } else if (host === "localhost" || host === "127.0.0.1") {
        return MainApp;
    } else {
        return MainApp;
    }
}

const routes = [
    {
        path: '/login',
        component: LoginPage,
        meta: { title: 'ARS Retail Portal Login' }
    },
    {
        path: '/clientlogin',
        component: ClientLoginPage,
        meta: { title: 'ARS Job Portal Login' }
    },
    {
        path: '/success',
        component: SuccessView,
        meta: { title: 'Thank you for your order! -- ARS' }
    },
    {
        path: '/cancel',
        component: CancelView,
        meta: { title: 'Order cancellation -- ARS' }
    },
    {
        path: '/',
        component: getRootComponent(),
        meta: { title: DEFAULT_TITLE }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to, _, next) => {
    document.title = to.meta.title || DEFAULT_TITLE;
    const host = window.location.hostname;

    const isClientDomain = host.endsWith('client.autorescuesolutions.com');
    const isMainDomain = host.endsWith('autorescuesolutions.com') && !isClientDomain;

    if (isClientDomain) {
        if (to.path === '/') {
            const authed = await isAuthenticated();
            if (!authed && to.path !== '/clientlogin') {
                next('/clientlogin');
                return;
            }
            if (to.path === '/clientlogin' && await isAuthenticated()) {
                next('/');
                return;
            }
        }
    } else if (isMainDomain) {
        if (to.path === '/') {
            const authed = await isAuthenticated();
            if (!authed && to.path !== '/login') {
                next('/login');
                return;
            }
        }
        if (to.path === '/login' && await isAuthenticated()) {
            next('/');
            return;
        }
    }

    if (to.query.success) {
        next('/success');
        return;
    } else if (to.query.cancel) {
        next('/cancel');
        return;
    }
    next();
});

async function isAuthenticated() {
    try {
      const response = await fetch('/authed');
      return response.status === 200;
    } catch (error) {
      console.error('Error checking authentication:', error);
      return false;
    }
}

export default router;