<template>
  <div class="header">
    <a href="https://autorescues.com/"><img src="@/assets/arsheader.webp" alt="ARS Header" class="ars-header" /></a>
   <a href="tel:+18339424008"><img src="@/assets/phone.svg" alt="Phone Icon" class="phone-icon" /></a>
  </div>
</template>

<script>
export default {
  name: 'AppHeader'
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: black; /* Add this line to set the background color to black */
}

.ars-header {
  height: 45px;
  object-fit: contain;
}

.phone-icon {
  height: 45px;
  object-fit: contain;
}

@media (max-width: 1300px) {
  .phone-icon {
  height: 35px;
}
.ars-header {
  height: 35px;
}
}

@media (max-width: 768px) {
    .header {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .content-wrapper {
        margin-top: 100px; 
    }
    .right-column {
        max-height: 300px;
        overflow-y: auto; 
    }
    .ars-header {
      height: 40px;
      object-fit: contain;
    }
    .phone-icon {
      height: 40px;
    }
}
</style>
