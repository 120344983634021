<template>
    <div class="form-field">
        <div class="form-row">
            <button @click="openNewTab"
                    :class="{'button-enabled': !isSubmitted, 'button-disabled': isSubmitted}"
                    class="create-button">
                Open Airtable
            </button>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { scriptState } from '@/store.js';

export default {
    setup() {
        const isSubmitted = computed({
            get: () => scriptState.isSubmitted,
            set: value => { scriptState.isSubmitted = value; }
        });
        return {
            isSubmitted
        };
    },
    props: {
        label: String,
    },
    methods: {
        openNewTab() {
            if (!scriptState.isSubmitted) {
                scriptState.isSubmitted = true;
                window.open("https://airtable.com/app19qWwwrbdMg96B/shriD7Ek3KIL6dnRn", '_blank');
                scriptState.isSubmitted = false;
            }
        }
    }
}
</script>

<style scoped>
body {
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}

.form-row {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 10px;
}

.form-field {
    font-size: 16px;
    align-items: center;
}

button {
    color: #fff;
    text-align: center;
    background-color: #000000;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6em;
    text-decoration: none;
    transition: background-color 0.3s, transform 0.2s;
    cursor: pointer;
    box-shadow: 0 20px 30px -10px rgba(24, 16, 99, .36);
    width: 100%;
}

.button-enabled {
    background-color: #000000;
    padding: 15px;
    color: white;
}

.button-disabled, :disabled {
    background-color: lightgray;
    color: darkgray;
    padding: 15px;
    cursor: not-allowed;
}

</style>
