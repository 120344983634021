<template>
    <div class="invoice-container">
        <div class="checkout-link">
            <a href="https://www.curbsidesos.com/sos/motorist?utm_source=ARSref&utm_medium=online&utm_campaign=webapp">Click here if you are not redirected</a>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        window.location.href = "https://www.curbsidesos.com/sos/motorist?utm_source=ARSref&utm_medium=online&utm_campaign=webapp";
    }
}
</script>

<style scoped>
.checkout-link {
    text-align: center;
    display: flex;
}
</style>
