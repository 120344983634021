<template>
    <div class="form-field">
        <div class="form-row" v-if="showFuelDelivery">
            <div class="radio-group">
                <label class="custom-radio">
                    <input type="radio" name="fuelSide" value="Fuel cap on left side of vehicle" @change="event => updateSelectedSide(event, 'fuelDelivery')" />
                    <span class="radio-label">Left</span>
                </label>
                <label class="custom-radio">
                    <input type="radio" name="fuelSide" value="Fuel cap on right side of vehicle" @change="event => updateSelectedSide(event, 'fuelDelivery')" />
                    <span class="radio-label">Right</span>
                </label>
            </div>
            Gas cap on the left or the right side of vehicle?
        </div>
        <div class="form-row" v-if="showTireChange">
            <div class="radio-group">
                <label class="custom-radio">
                    <input type="radio" name="tireSide" value="Flat on left of vehicle" @change="event => updateSelectedSide(event, 'tireChange')" />
                    <span class="radio-label">Left</span>
                </label>
                <label class="custom-radio">
                    <input type="radio" name="tireSide" value="Flat on right side of vehicle" @change="event => updateSelectedSide(event, 'tireChange')" />
                    <span class="radio-label">Right</span>
                </label>
            </div>
            Flat on the left or the right side of vehicle?
        </div>
        <div class="form-row" v-if="scriptState.formData.highwayCall === 'yes'">
            Which side of your vehicle is traffic on?
            <div class="radio-group">
                <label class="custom-radio">
                    <input type="radio" name="highwaySide" value="Traffic on left side of vehicle" @change="event => updateSelectedSide(event, 'highway')" v-model="scriptState.hwySide" />
                    <span class="radio-label">Left</span>
                </label>
                <label class="custom-radio">
                    <input type="radio" name="highwaySide" value="Traffic on right side of vehicle" @change="event => updateSelectedSide(event, 'highway')" v-model="scriptState.hwySide" />
                    <span class="radio-label">Right</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import { scriptState } from '@/store.js';

export default {
    setup() {
        return { scriptState };
    },
    computed: {
        showFuelDelivery() {
            return scriptState.formData.selectedServices.includes('Fuel Delivery');
        },
        showTireChange() {
            return scriptState.formData.selectedServices.includes('Tire Change');
        }
    },
    methods: {
        updateSelectedSide(event, group) {
            if (group !== 'highway') {
                const value = event.target.value;
                const groupIndex = scriptState.selectedSide.findIndex(item => item.group === group);
                if (groupIndex > -1) {
                    scriptState.selectedSide[groupIndex].value = value;
                } else {
                    scriptState.selectedSide.push({ group, value });
                }
            }
            this.updateFieldValidity();
        },
        updateFieldValidity() {
            let isValid = true;
            if (this.showFuelDelivery) {
                const fuelDeliverySelected = scriptState.selectedSide.some(item => item.group === 'fuelDelivery');
                isValid = isValid && fuelDeliverySelected;
            }
            if (this.showTireChange) {
                const tireChangeSelected = scriptState.selectedSide.some(item => item.group === 'tireChange');
                isValid = isValid && tireChangeSelected;
            }
            if (scriptState.formData.highwayCall === 'yes') {
                const highwaySideSelected = scriptState.hwySide.length > 0;
                isValid = isValid && highwaySideSelected;
            }
            scriptState.fieldsValid = isValid;
        }
    }
};
</script>

<style scoped>
.radio-group {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-evenly;
}

.custom-radio {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px;
  font-size: 1rem; 
}

.custom-radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 35px;
  width: 35px;
  margin: 0 10px 0 0;
  flex-shrink: 0;
  background-color: #f0f0f0;
  border: 2px solid #d0d0d0;
  border-radius: 50%; /* Circular shape */
  cursor: pointer;
  transition: background-color 0.2s;
}

.custom-radio input[type="radio"]:checked {
  background-color: #2196F3;
  border-color: #2196F3;
}

.custom-radio span.radio-label {
  pointer-events: none;
}

.custom-radio input[type="radio"]:not(:checked):hover {
  background-color: #e0e0e0;
}

.custom-radio .radio-label {
  display: inline;
}
</style>
