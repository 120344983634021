import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router.js'
import { createGtm } from '@gtm-support/vue-gtm';
import VueGoogleMaps, { GMapAutocomplete } from '@fawmi/vue-google-maps';

const app = createApp(App);

app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyABtM8-1SIY7iXujlEO_H3YiGdGY6GSlKo',
    libraries: 'places',
  },
});

app.use(
  createGtm({
    id: "GTM-5LGMVH4",
    vueRouter: router,
    ignoredViews: ["MainApp", "LoginPage", "LocationView", "CancelView", "SuccessView"],
  })
);
app.component('GMapAutocomplete', GMapAutocomplete);
app.use(router);
app.mount('#app');