<template>
  <div v-if="show" class="popover">
    <div class="content">
      <h2>Staging Access</h2><hr>
      <h4>This is a test site. Do not use this for actual requests.</h4>
      <input type="password" v-model="password" placeholder="Enter password">
      <button @click="checkPassword">Submit</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      password: '',
      correctPassword: 'ars'
    };
  },
  methods: {
    checkPassword() {
      if (this.password === this.correctPassword) {
        this.show = false;
      } else {
        alert('Incorrect password');
      }
    }
  },
  mounted() {
    if (window.location.hostname.includes('staging')) {
      this.show = true;
    }
  }
};
</script>

<style scoped>
.popover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.content {
    font-family: '', sans-serif;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

button {
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #fff;
    color: #fff;
    background-color: #4682b4;
    margin-bottom: 10px;
    margin-left: 5px;
}

input {
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
}
</style>
