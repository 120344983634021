<template>
        <div class="form-field">
            <div class="form-row" v-if="techsLoaded">
                <div class="form-field">
                    Available Techs: {{ numTechs }}
                </div>
            </div>
            <div class="form-row" v-if="etaLoaded">
                <div class="form-field">
                    Average market ETA: {{ avgEta }}
                </div>
            </div>
        </div>
</template>

<script>
import { ref } from 'vue';
import { scriptState } from  '@/store.js';
import axios from 'axios';

export default {
    setup() {
        const numTechs = ref(0);
        const avgEta = ref(0);
        const etaLoaded = ref(false);
        const techsLoaded = ref(false);

        const loadMarketData = async () => {
            try {
                const payload = { market: scriptState.formData.market };
                const responseEta = await axios.post('/eta', payload);
                if (responseEta.data.valid) {
                    avgEta.value = responseEta.data.eta;
                } else {
                    avgEta.value = responseEta.data.eta;
                }
                etaLoaded.value = responseEta.data.valid;
                if (scriptState.formData.provider === "ARS") {
                    const responseTechs = await axios.post('/techs', payload);
                    if (responseTechs.data.valid) {
                        numTechs.value = responseTechs.data.techs;
                    } else {
                        numTechs.value = responseTechs.data.techs;
                    }
                    techsLoaded.value = responseTechs.data.valid;
                }
            } catch (error) {
                console.error("Error loading market data:", error);
            }
        };

        
        loadMarketData();

        return {
            numTechs,
            avgEta,
            etaLoaded,
            techsLoaded
        };
    },
    props: {
        label: String,
    },
}
</script>

<style scoped>

.form-row {
    width: 100%;
    /* display: flex; */
    /* justify-content: flex-start; */
    align-items: center;
    gap: 10px;
}

.form-field {
    font-size: 16px;
    align-items: center;
}

.textarea-wide {
    width: 100%;
    height: 80px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
}
</style>