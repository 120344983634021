<template>
    <div class="comment-container">
        <div class="form-field">
            <div class="form-row">
                <div class="form-field">
                    <textarea id="comment" v-model="comment" class="textarea-wide" placeholder="Enter your comments and details"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { publicState } from  '@/pubstore.js';
export default {
    setup() {
        const comment = computed({
            get: () => publicState.formData.comment,
            set: value => { publicState.formData.comment = value; }
        });
        return {
            comment,
        };
    },
    props: {
        label: String,
    },
}
</script>

<style scoped>

.comment-container {
  flex: 1;
  margin-bottom: 10px; /* Default margin */
  background-color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 768px) {
  .comment-container {
    margin-bottom: 15px; /* Updated margin for mobile */
  }
}

.form-row {
    width: 100%;
    align-items: center;
    gap: 10px;
}


.form-field {
    font-size: 16px;
    align-items: center;
}


.textarea-wide {
    width: 100%;
    height: 180px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
}

</style>